<template>
  <div class="InviteForm">
    <b-form novalidate class="InviteForm__form" :validated="wasValidated" @submit.prevent="handleSubmit">
      <b-form-row>
        <b-col sm="6">
          <b-form-group label-sr-only label="nome" label-for="name">
            <b-form-input required placeholder="nome" minlength="5" id="name" v-model="formData.name"></b-form-input>
            <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-sr-only label="cpf" label-for="cpf">
            <b-form-input
              required
              placeholder="cpf"
              minlength="11"
              maxlength="11"
              id="cpf"
              v-model="formData.cpf"
              :class="{
                'is-invalid': cpfInvalid,
                'border-danger': cpfInvalid,
                'icon-is-invalid': cpfInvalid,
                'border-success': cpfValid,
                'icon-is-valid': cpfValid
              }"
              @keyup="showCpfValidity"
            ></b-form-input>
            <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-sr-only label="rg" label-for="rg">
            <b-form-input required placeholder="rg" maxlength="13" id="rg" v-model="formData.rg"></b-form-input>
            <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="6">
          <b-form-group label-sr-only label="email" label-for="email">
            <b-form-input required placeholder="email" type="email" id="email" v-model="formData.email"></b-form-input>
            <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label-sr-only label="celular" label-for="cellphone">
            <b-form-input required placeholder="celular com ddd" minlength="10" type="tel" id="cellphone" v-model="formData.cellphone"></b-form-input>
            <b-form-invalid-feedback>Obrigatório (mín. 10 caracteres)</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-sr-only label="estado" label-class="h5">
            <b-form-select required id="state" v-model="formData.state">
              <option :value="''" disabled>estado</option>
              <option v-for="(state, index) in states" :key="index" :value="state">{{ state.toLowerCase() }}</option>
            </b-form-select>
            <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group label="Você é empreendedor(a) na economia criativa?" label-for="is_entrepreneur">
            <b-form-radio-group plain required id="is_entrepreneur" name="is_entrepreneur" v-model="formData.is_entrepreneur">
              <b-form-radio class="form-check-input--bigger" v-for="(option, index) in ['Não', 'Sim']" :key="index" :value="option">{{ option.toLowerCase() }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col sm="8">
          <b-form-group label="área de atuação" label-for="economy_area">
            <b-form-input required placeholder="digite 'nenhuma' se não for o caso" id="economy_area" v-model="formData.economy_area"></b-form-input>
            <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-button class="align-self-lg-center" type="submit" variant="outline-primary" :disabled="loading">Salvar</b-button>
    </b-form>
  </div>
</template>

<script>
import { checkCpfValidity } from "@/helpers/utils";

export default {
  name: "InviteForm",
  props: {
    loading: Boolean
  },
  data() {
    return {
      states: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
      formData: {
        name: "",
        cpf: "",
        rg: "",
        email: "",
        cellphone: "",
        state: "",
        is_entrepreneur: "",
        economy_area: ""
      },
      wasValidated: false,
      cpfInvalid: false,
      cpfValid: false
    }
  },
  methods: {
    handleSubmit(e) {
      this.wasValidated = true;
      this.showCpfValidity();
      if (e.target.checkValidity() && checkCpfValidity(this.formData.cpf)) {
        this.$emit("formSubmit", this.formData);
      }
    },
    showCpfValidity() {
      if (this.wasValidated) {
        if (!checkCpfValidity(this.formData.cpf)) {
          this.cpfValid = false;
          this.cpfInvalid = true;
        } else {
          this.cpfValid = true;
          this.cpfInvalid = false;
        }
      }
    },
  }
}
</script>

<style lang="scss">
.InviteForm {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

#cpf.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23F00' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23F00' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  }
}

.form-check-input--bigger input[type="radio"] {
  transform: scale(1.2);
}
</style>
