import axios from 'axios'
import { getServerURL } from '@/const'
import Auth from '@/helpers/auth'

// initial state
const state = {
  token: localStorage.getItem('token') || null,
  type: localStorage.getItem('type') || null,
  user: {},
  loading: false,
  validationUrl: window.location.host + '/validar-email'
}

// getters
const getters = {
  isLoggedIn: state => state.token !== null,
  isEntrepreneur: state => state.type == 0,
  isCurator: state => state.type == 1,
}

// actions
const actions = {
  login({ commit }, { email, password }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .post(getServerURL('/user/login'), { email, password })
        .then(response => {
          if (response.data.responseData.success == true) {
            const token = response.data.responseData.token
            const type = response.data.responseData.type
            axios.defaults.headers.common[Auth.authKey] = 'Bearer ' + token
            localStorage.setItem('token', token)
            localStorage.setItem('type', type)
            commit('setToken', token)
            commit('setType', type)
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  },
  logout({ commit }) {
    // talvez nao seja preciso consultar api para deslogar,
    // bastando apenas remover token do navegador
    return new Promise(resolve => {
      commit('startLoading')
      localStorage.removeItem('token')
      localStorage.removeItem('type')
      delete axios.defaults.headers.common[Auth.authKey]
      commit('setToken', null)
      commit('setType', null)
      commit('finishLoading')
      resolve()
    })

    // VALIDACAO DE LOGOUT USANDO API
    // return new Promise((resolve, reject) => {
    //   axios
    //     .post(getServerURL('/user/logout'))
    //     .then(response => {
    //       localStorage.removeItem('token')
    //       delete axios.defaults.headers.common[Auth.authKey]
    //       commit('setToken', null)
    //       commit('finishLoading')
    //       resolve(response)
    //     })
    //     .catch(error => {
    //       localStorage.removeItem('token')
    //       delete axios.defaults.headers.common[Auth.authKey]
    //       commit('setToken', null)
    //       commit('finishLoading')
    //       reject(error)
    //     })
    // })
  },
  forgotPassword ({ commit }, { email }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .post(
          getServerURL('/user/forgotPassword'),
          { email }
        )
        .then(response => {
          if (response.data.responseData.success == true) {
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  },
  register({ commit, state }, user) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .post(getServerURL('/user/signup', '201'), {
          user,
          validationUrl: state.validationUrl
        })
        .then(response => {
          if (response.data.responseData.success == true) {
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  },
  getUser({ commit }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL('/user'))
        .then(response => {
          if (response.data.responseData.success == true) {
            const user = response.data.responseData.user
            commit('setUser', user)
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  },
  updateUser({ commit, state }) {

    commit('startLoading')

    // objeto FormData que sera enviado
    const formData = new FormData()

    // adicao de arquivos para upload deve ser separado...
    if (state.user.avatarFile) formData.append('avatarFile', state.user.avatarFile)

    // dos dados restantes do formulario enviados como JSON
    formData.append('data', JSON.stringify(state.user))

    return new Promise((resolve, reject) => {
      axios
        .post(getServerURL('/user/update'), formData)
        .then(response => {
          if (response.data.responseData.success == true) {
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  },
  validateEmail({ commit }, emailValidationToken) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL('/user/signup/validation?i=' + emailValidationToken) )
        .then(response => {
          if (response.data.responseData.success == true) {
            const token = response.data.responseData.token
            const type = response.data.responseData.type
            axios.defaults.headers.common[Auth.authKey] = 'Bearer ' + token
            localStorage.setItem('token', token)
            localStorage.setItem('type', type)
            commit('setToken', token)
            commit('setType', type)
            commit('finishLoading')
            resolve(response)
          } else {
            commit('finishLoading')
            resolve(response)
          
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  startLoading(state) {
    state.loading = true
  },
  finishLoading(state) {
    state.loading = false
  },
  setUser(state, user) {
    state.user = user
  },
  setToken(state, token) {
    state.token = token
  },
  setType(state, type) {
    state.type = type
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
