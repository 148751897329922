<template>
  <div class="Navbar">
    <b-navbar type="dark">
      <b-navbar-brand :to="{ name: 'home' }" class="btn btn-link">
        <i class="fas fa-long-arrow-alt-left"></i> Voltar
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <div class="ml-auto">
          <template v-if="isLoggedIn">
            <template v-if="isCurator">
              <router-link class="btn btn-link ml-7" :to="{ name: 'curatorDashboard' }">Painel</router-link>
            </template>
            <template v-else>
              <router-link class="btn btn-link ml-7" :to="{ name: 'entrepreneurDashboard' }">Painel</router-link>
            </template>
          </template>
          <template v-else>
            <router-link class="btn btn-link ml-7" :to="{ name: 'home', params: { login: true } }"><i class="far fa-user-circle"></i> Login</router-link>
          </template>
        </div>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters["user/isLoggedIn"];
      }
    },
    isCurator: {
      get() {
        return this.$store.getters["user/isCurator"];
      }
    }
  }
};
</script>

<style>
</style>
