<template>
  <div class="TheHeroMobile col-md-5 col-lg-4">
    <div class="action-bar">
      <template v-if="isLoggedIn">
        <template v-if="isCurator">
          <router-link class="btn btn-link ml-7" :to="{ name: 'curatorDashboard' }">Painel</router-link>
        </template>
        <template v-else>
          <router-link class="btn btn-link ml-7" :to="{ name: 'entrepreneurDashboard' }">Painel</router-link>
        </template>
      </template>
      <template v-else>
        <b-button variant="link" v-if="showLogin || showForgotPassword || showActivateCuratorProfile" @click="showLogin = false; showForgotPassword = false; showActivateCuratorProfile = false;">Voltar</b-button>
        <b-button variant="link" v-else @click="showLogin = true">
          <i class="far fa-user-circle"></i> Login
        </b-button>
      </template>
    </div>
    <TheHeroAside
      :loading="loading"
      :custom-data="customData"
      :view-data="viewData"
      :show-login="showLogin"
      :show-forgot-password="showForgotPassword"
      :activate-curator-profile="showActivateCuratorProfile"
      @showForgotPassword="handleShowForgotPassword" />
  </div>
</template>

<script>
import TheHeroAside from "@/components/TheHeroAside";

export default {
  name: "TheHeroMobile",
  components: {
    TheHeroAside
  },
  props: [
    "loading",
    "custom-data",
    "view-data",
    "login",
    "activate-curator-profile"
  ],
  data() {
    return {
      showLogin: this.login || false,
      showForgotPassword: false,
      showActivateCuratorProfile: this.activateCuratorProfile || false
    };
  },
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters["user/isLoggedIn"];
      }
    },
    isCurator: {
      get() {
        return this.$store.getters["user/isCurator"];
      }
    }
  },
  methods: {
    handleShowForgotPassword() {
      this.showLogin = false;
      this.showForgotPassword = true;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
