<template>
  <button class="ScrollTopButton" @click="handleClick">
    <i class="fas fa-chevron-up fa-2x"></i>
  </button>
</template>

<script>
export default {
  name: "ScrollTopButton",
  props: {
    href: String
  },
  methods: {
    handleClick () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      if (this.href) {
        window.location.href = this.$route.path + this.href;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ScrollTopButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 30px;
  bottom: 90px;
  color: #F2F2F2;
  background-image: linear-gradient(90deg, #09AFE9, #29F49A);
  border-image-source: linear-gradient(90deg, #09AFE9, #29F49A);
  border-image-slice: 1;
  border-radius: 50%;
  border: 0;
  outline: none;

  @media (min-width: 768px) {
    right: 25px;
    bottom: 60px;
  }
}
</style>
