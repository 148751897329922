<template>
  <div class="ProjectDetailRating">
    <p>Basta avaliar com uma nota de 1 a 5, sendo:</p>
    <ol>
      <li>Não atende este critério</li>
      <li>Atende pouco este critério</li>
      <li>Atende parcialmente este critério</li>
      <li>Atende satisfatoriamente este critério</li>
      <li>É excelente neste critério</li>
    </ol>
    <b-form :class="{ 'was-validated': wasValidated }" novalidate @submit.prevent="handleSubmit">
      <!-- <div class="ProjectDetailRatingItem" v-for="(rating, index) in project.ratings" :key="index">
        <b-form-row class="mb-7">
          <b-col cols="9" lg="10" class="d-flex align-items-center"><label :for="`ProjectDetailRatingItem__input-${index}`" class="h5">{{ rating.criteria_description }}</label></b-col>
          <b-col cols="3" lg="2"><b-input :id="`ProjectDetailRatingItem__input-${index}`" type="number" min="0" max="10" v-model="rating.rating"></b-input></b-col>
        </b-form-row>
      </div> -->
      <div class="ProjectDetailRatingItem" v-for="(criteria, index) in criterias" :key="index">
          
        <b-form-row class="mb-7" v-if="criteria.type">
            <b-col cols="12" lg="10" class="d-flex align-items-center pre"><label :for="`ProjectDetailRatingItem__input-${index}`" class="h5">{{ criteria.name }}</label></b-col>
        </b-form-row>
        <b-form-row class="mb-7" v-else>
            <b-col cols="9" lg="10" class="d-flex align-items-center pre"><label :for="`ProjectDetailRatingItem__input-${index}`" class="p">{{ criteria.name }}</label></b-col>
            <b-col cols="3" lg="2"><b-input class="form-control-slim" :id="`ProjectDetailRatingItem__input-${index}`" type="number" min="0" max="5" v-model="ratings[criteria.id]" required></b-input></b-col>
        </b-form-row>
 

      </div>
      <div class="d-flex justify-content-center mb-7" v-if="loading">
        <i class="fas fa-circle-notch fa-spin fa-2x"></i>
      </div>
      <div class="mb-7" v-if="showSuccess || showErrorMessage">
        <b-alert class="mb-0" variant="success" dismissible fade v-model="showSuccess">
          <h5 class="h5">Sucesso!</h5>
          <p class="mb-0">Projeto avaliado com sucesso!</p>
        </b-alert>
        <b-alert class="mb-0" variant="danger" dismissible fade v-model="showErrorMessage">
          <h5 class="h5">Erro!</h5>
          <p class="mb-0">{{ errorMessage }}</p>
        </b-alert>
      </div>
      <div class="d-flex justify-content-center">
        <b-button type="submit" variant="outline-primary">Salvar</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import rating from "@/api/services/curator";

export default {
  name: "ProjectDetailRating",
  props: {
    project: Object,
    criteriasProp: Array,
    ratingsProp: Array
  },
  data() {
    return {
      loading: 0,
      successCount: 0,
      errorCount: 0,
      wasValidated: false,
      showSuccess: false,
      showErrorMessage: false,
      errorMessage: "",
      criterias: this.criteriasProp || [],
      ratings: {}
    }
  },
  methods: {
    handleSubmit(e) {
      this.wasValidated = true;
      if (e.target.checkValidity()) {
        this.loading = 1;
        this.successCount = 0;
        this.errorCount = 0;

        const formattedRatings = [];
        Object.keys(this.ratings).forEach(key => {
          formattedRatings.push({ criteriaId: key, score: this.ratings[key] });
        });

        rating
          .save({
            projectId: this.project.id,
            ratings: formattedRatings
          })
          .then(() => {
            this.successCount++;
          })
          .catch(({ response: { data: { responseData } } }) => {
            this.errorMessage = responseData.errorMessage;
            this.errorCount++;
          })
          .catch(() => {
            this.errorMessage = "Serviço indisponível.";
            this.errorCount++;
          })
          .finally(() => {
            this.loading--;
            if (!this.loading) {
              if (!this.errorCount) {
                this.showSuccess = true;
              } else if (!this.successCount) {
                this.showErrorMessage = true;
              } else {
                this.showSuccess = true;
                this.showErrorMessage = true;
              }
            }
          });
      }
    }
  },
  mounted() {
    if (this.ratingsProp.length) {
      this.ratingsProp.forEach(rating => {
        this.ratings = {
          ...this.ratings,
          [`${rating.criteria_id}`]: rating.score
        };
      }, this);
    } else {
      this.criterias.forEach(criteria => {
        this.ratings = {
          ...this.ratings,
          [`${criteria.id}`]: "0"
        };
      }, this);
    }
  }
};
</script>

<style lang="scss">
</style>
