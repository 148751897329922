<template>
  <div class="Rating">
    <BaseJumbotron title="Avaliação"/>
    <BaseContainer>
      <RatingForm class="mb-7" :questions="questions" :was-validated="wasValidated" @formSubmit="handleSubmit" v-show="!showSuccess" v-if="questions.length"/>
      <b-alert dismissible fade class="mb-0" variant="danger" v-model="showErrorMessage">
        <h5 class="h5">Erro!</h5>
        <p class="mb-0">{{ errorMessage }}</p>
      </b-alert>
      <BaseSplashScreen hide-btn variant="success" title="Avaliação salva com sucesso!" v-show="showSuccess"/>
      <BaseSpinner v-show="loading"/>
    </BaseContainer>
  </div>
</template>

<script>
import RatingService from "@/api/services/rating";
import BaseJumbotron from "@/components/BaseJumbotron";
import BaseContainer from "@/components/BaseContainer";
import BaseSpinner from "@/components/BaseSpinner";
import BaseSplashScreen from "@/components/BaseSplashScreen";
import RatingForm from "@/components/RatingForm";

export default {
  name: "Rating",
  components: {
    BaseJumbotron,
    BaseContainer,
    BaseSpinner,
    BaseSplashScreen,
    RatingForm
  },
  data() {
    return {
      i: "",
      questions: [],
      loading: false,
      wasValidated: false,
      showErrorMessage: false,
      showSuccess:  false,
      errorMessage: "",
    }
  },
  methods: {
    handleSubmit(e, answers) {
      this.wasValidated = true;
      if (e.target.checkValidity()) {
        this.loading = true;

        const formattedAnswers = [];
        Object.keys(answers).forEach(key => {
          formattedAnswers.push({ question_id: key, answer: answers[key] });
        });

        RatingService
          .create({ i: this.i, questions: formattedAnswers })
          .then(() => this.showSuccess = true)
          .catch(({ response: { data: { responseData } } }) => {
            this.errorMessage = responseData.errorMessage;
            this.showErrorMessage = true;
          })
          .catch(() => {
            this.errorMessage = "Serviço indisponível.";
            this.showErrorMessage = true;
          })
          .finally(() => this.loading = false);
      }
    }
  },
  mounted() {
    if (!this.$route.query.i) {
      this.$router.push({ name: "home" });
    } else {
      this.loading = true;
      this.i = this.$route.query.i;
      RatingService
        .getQuestions()
        .then(({ data: { responseData } }) => {
          this.questions = responseData.questions;
        })
        .catch(({ response: { data: { responseData } } }) => {
          this.errorMessage = responseData.errorMessage;
          this.showErrorMessage = true;
        })
        .catch(() => {
          this.errorMessage = "Serviço indisponível.";
          this.showErrorMessage = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.Rating {
  margin-bottom: 150px;
}
</style>
