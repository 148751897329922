import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import Vuex from 'vuex'
import './plugins/bootstrap-vue'
import App from './App'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
// import './registerServiceWorker'
import './unregisterServiceWorker';
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.performance = true

Vue.use(Vuex)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueAnalytics, {
  id: 'UA-144691199-1',
  router,
})

new Vue({
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  router,
  store
}).$mount('#app')



