<template>
  <div id="validate-email">
    <div class="m-3" v-if="showSuccess">Email validado com sucesso. Redirecionando...</div>
    <div v-if="showError" class="d-flex flex-column align-items-center pt-11">
      <p>{{ errorMessage }}</p>
      <router-link :to="{ name: 'home' }" class="btn btn-outline-primary">Ir pra home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'validate-email',
  data() {
    return {
      showSuccess: false,
      showError: false,
      errorMessage: ''
    }
  },
  created() {
    this.$store.dispatch('user/validateEmail', this.$route.query.i)
    .then(() => {
      this.showSuccess = true
      setTimeout(() => this.$router.push({ name: 'entrepreneurDashboard' }), 2000)
    })
    .catch(error => {
      this.errorMessage = error.response.data.responseData.errorMessage
      this.showError = true
    })
  }
}
</script>

<style>

</style>
