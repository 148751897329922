<template>
    <div id="logout">
      <div class="m-3">Saindo...</div>
    </div>
</template>

<script>
export default {
  name: 'logout',
  created() {
    this.$store.dispatch('user/logout')
    .then(() => {
      this.$router.push({ name: 'home' })
    })
  }
}
</script>

<style>

</style>
