import axios from 'axios'
import moment from 'moment'
import { getServerURL } from '@/const'

const state = {
  posts: [],
  loading: false
}

const getters = {}

const actions = {
  getPosts({ commit }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL('/posts'))
        .then(response => {
            const posts = response.data.responseData.posts
            posts.forEach((post, index, array) => {
              array[index].publish_date = moment(post.publish_date).format('DD/MM/YYYY HH:mm')
            })
            // console.logging(posts)
            commit('setPosts', posts)
            commit('finishLoading')
            resolve(response)
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  }
}

const mutations = {
  startLoading(state) {
    state.loading = true
  },
  finishLoading(state) {
    state.loading = false
  },
  setPosts(state, posts) {
    state.posts = posts
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
