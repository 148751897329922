<template>
  <div id="session-expired">
    <div class="d-flex flex-column align-items-center pt-11">
      <h3>Sessão Expirada. Redirecionando...</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "session-expired",
  data() {
    return {};
  },
  created() {
    this.$store
      .dispatch("user/logout")
      .then(() => {
        setTimeout(
          () => this.$router.push({ name: "home", params: { login: true }}),
          2000
        );
      })
      .catch(() => (this.showError = true));
  }
};
</script>

<style>
</style>
