<template>
  <div class="ProjectDetail">
    <b-row>
      <b-col>
        <b-button variant="link" class="mb-9" @click="handleClick">
          <i class="fas fa-long-arrow-alt-left"></i> Voltar
        </b-button>
        <h3 class="h3 mb-9 text-center text-lg-left">{{ project.name }}</h3>
        <b-nav class="mb-7 mx-n7 justify-content-center justify-content-lg-start">
          <b-nav-item class="h5 text-uppercase" @click="showProjectMedia = false; showProjectEntrepreneur = false; showProjectRating = false; showProjectInfo = true;">
            <div class="progressive-form-nav-item" :class="{ 'active': showProjectInfo }">Informações</div>
          </b-nav-item>
          <b-nav-item class="h5 text-uppercase" @click="showProjectInfo = false; showProjectEntrepreneur = false; showProjectRating = false; showProjectMedia = true;">
            <div class="progressive-form-nav-item" :class="{ 'active': showProjectMedia }">Mídias</div>
          </b-nav-item>
          <template v-if="isCurator">
            <b-nav-item class="h5 text-uppercase" @click="showProjectInfo = false; showProjectMedia = false; showProjectRating = false; showProjectEntrepreneur = true;">
              <div class="progressive-form-nav-item" :class="{ 'active': showProjectEntrepreneur }">Empreendedor(a)</div>
            </b-nav-item>
            <b-nav-item class="h5" :disabled="disableRating" @click="showProjectInfo = false; showProjectMedia = false; showProjectEntrepreneur = false; showProjectRating = true;">
              <div class="progressive-form-nav-item" :class="{ 'active': showProjectRating }">Avaliar</div>
            </b-nav-item>
          </template>
        </b-nav>
        <div v-show="showProjectInfo">
          <div class="mb-11" v-if="project.state_name">
            <h3 class="h4">Estado</h3>
            <p>{{ project.state_name }}</p>
          </div>
          <div class="mb-11" v-if="project.city_name">
            <h3 class="h4">Cidade</h3>
            <p>{{ project.city_name }}</p>
          </div>
          <div class="mb-11" v-if="project.objective">
            <h3 class="h4">Objetivo</h3>
            <p>{{ project.objective }}</p>
          </div>
          <div class="mb-11" v-if="project.presentation">
            <h3 class="h4">Apresentação</h3>
            <p>{{ project.presentation }}</p>
          </div>
          <div class="mb-11" v-if="project.social_impact">
            <h3 class="h4">Impacto social</h3>
            <p>{{ project.social_impact }}</p>
          </div>
          <div class="mb-11" v-if="project.target_audience_text">
            <h3 class="h4">Público-alvo</h3>
            <p>{{ project.target_audience_text }}</p>
          </div>
          <div class="mb-11" v-if="project.why_should_win">
            <h3 class="h4">Por que merece ganhar o Prêmio</h3>
            <p>{{ project.why_should_win }}</p>
          </div>
          <!-- <div class="mb-11" v-if="project.facebook || project.instagram || project.twitter">
            <h3 class="h4">Redes sociais</h3>
            <div class="mx-n3">
              <a class="mx-3" :href="project.facebook" target="_blank" v-if="project.facebook"><i class="fab fa-facebook"></i> Facebook</a>
              <a class="mx-3" :href="project.instagram" target="_blank" v-if="project.instagram"><i class="fab fa-twitter"></i> Instagram</a>
              <a class="mx-3" :href="project.twitter" target="_blank" v-if="project.twitter"><i class="fab fa-twitter"></i> Twitter</a>
            </div>
          </div> -->
          <div class="mb-11" v-if="project.site || project.twitter">
            <h3 class="h4">Links</h3>
            <div class="mx-n3">
              <a class="mx-3" :href="project.site" target="_blank"><i class="fas fa-globe"></i> Website</a>
              <a class="mx-3" :href="project.twitter" target="_blank" v-if="project.twitter"><i class="fas fa-globe"></i> Link Adicional</a>
            </div>
          </div>
        </div>
        <div v-show="showProjectMedia">
          <div class="mb-11" v-if="project.video_url">
            <h3 class="h4">Vídeo</h3>
            <div class="mx-n3">
              <a class="mx-3" :href="project.video_url" target="_blank"><i class="fab fa-youtube"></i> Abrir vídeo</a>
            </div>
          </div>
          <div class="mb-11" v-if="(project.picturesUrls && project.picturesUrls.length)">
            <h3 class="h4">Arquivos</h3>
            <div class="form-group d-flex flex-column">
              <b-row class="mx-n3 pb-n7">
                <b-col class="picture-preview px-3 pb-7" cols="6" md="4" lg="3" v-for="(pictureUrl, index) in project.picturesUrls" :key="index">
                  <a class="picture-preview__link w-100 h-100" :href="pictureUrl" target="_blank">
                    <template v-if="pictureUrl.match(/(bmp|gif|jpeg|jpg|png|svg)$/)">
                      <img class="picture-preview__img w-100 h-100" :src="pictureUrl" />
                    </template>
                    <template v-else>
                      <i class="far fa-file picture-preview__icon"></i>
                      <div class="picture-preview__name">
                        {{ pictureUrl.split('/')[pictureUrl.split('/').length - 1] }}
                      </div>
                    </template>
                  </a>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="mb-11" v-if="!project.video_url && !(project.picturesUrls && project.picturesUrls.length)">
            <p>Nenhuma mídia encontrada.</p>
          </div>
        </div>
        <template v-if="isCurator">
          <div v-show="showProjectEntrepreneur">
            <b-row no-gutters v-if="project.entrepreneur">
              <b-col cols="12" lg="2" class="d-flex justify-content-center mb-7 mr-lg-7">
                <img :src="project.entrepreneur.avatarUrl" width="100" height="100" alt="">
              </b-col>
              <b-col>
                <h4 class="h4 mb-7 text-break text-center text-lg-left">{{ project.entrepreneur.name }}</h4>
                <article class="mb-7">{{ project.entrepreneur.about }}</article>
                <div class="social-links justify-content-center justify-content-lg-start">
                  <a class="social-link" v-if="project.entrepreneur.facebook" :href="project.entrepreneur.facebook"><i class="fab fa-facebook"></i></a>
                  <a class="social-link" v-if="project.entrepreneur.twitter" :href="project.entrepreneur.twitter"><i class="fab fa-twitter"></i></a>
                  <a class="social-link" v-if="project.entrepreneur.instagram" :href="project.entrepreneur.instagram"><i class="fab fa-instagram"></i></a>
                  <a class="social-link" v-if="project.entrepreneur.email" :href="'mailto:' + project.entrepreneur.email"><i class="fas fa-envelope"></i></a>
                </div>
              </b-col>
            </b-row>
            <div class="mb-11" v-if="!project.entrepreneur">
              <p>Nenhum dado encontrado.</p>
            </div>
          </div>
          <ProjectDetailRating :project="project" :criterias-prop="criterias" :ratings-prop="ratings" v-show="showProjectRating" v-if="!disableRating" />
          
          <b-button variant="link" class="mb-9" @click="handleClick">
            <i class="fas fa-long-arrow-alt-left"></i> Voltar
          </b-button>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProjectDetailRating from "@/components/ProjectDetailRating";

export default {
  name: "ProjectDetail",
  components: {
    ProjectDetailRating
  },
  props: {
    disableRating: Boolean,
    project: Object,
    criterias: Array,
    ratings: Array
  },
  data() {
    return {
      showProjectInfo: true,
      showProjectMedia: false,
      showProjectEntrepreneur: false,
      showProjectRating: false
    }
  },
  computed: {
    isCurator: {
      get() {
        return this.$store.getters["user/isCurator"];
      }
    }
  },
  methods: {
    handleClick () {
      this.showProjectInfo = true;
      this.showProjectMedia = false;
      this.showProjectEntrepreneur = false;
      this.showProjectRating = false;
      this.$emit('itemDismissed');
    }
  }
}
</script>

<style lang="scss" scoped>
.picture-preview {
  height: 190px;
  overflow: hidden;

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__img {
    object-fit: cover;
  }

  &__icon {
    font-size: 4rem;
    margin-bottom: 0.85rem;
  }

  &__name {
    word-break: break-all;
  }

  @media (min-width: 576px) {
    height: 250px;
  }

  @media (min-width: 768px) {
    height: 190px;
  }
}
</style>
