<template>
  <b-nav class="card-row-nav mb-7 mx-n11 mx-lg-n7" :class="{ 'card-row-nav--scrollable': scrollable }">
    <b-nav-item
      class="h4 text-uppercase mb-0 mb-lg-1"
      :link-classes="['card-row-nav__link', 'px-0', 'mx-7', { 'active': activeItem === item.id }]"
      v-for="item in items"
      :key="item.id"
      @click="handleClick(item)"
    >{{ item.name }}</b-nav-item>
  </b-nav>
</template>

<script>

export default {
  name: "CardRowNav",
  props: {
    scrollable: Boolean,
    items: Array,
    initialActiveItem: String
  },
  data() {
    return {
      activeItem: this.initialActiveItem || null
    }
  },
  methods: {
    handleClick(item) {
      if (this.activeItem === item.id) {
        this.activeItem = 0;
        this.$emit('itemDeactivated')
      } else {
        this.activeItem = item.id;
        this.$emit('itemActivated', item)
      }
    },
    clear() {
      this.activeItem = null;
    },
    reset() {
      this.activeItem = this.initialActiveItem || null
    }
  }
};
</script>

<style lang="scss" scoped>
.card-row-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  &--scrollable {
    overflow: auto hidden;
  }

  &__link {
    &.active {
      padding-bottom: 0.25rem;
      border-bottom: 3.5px solid #049452;
    }
  }

  &::before,
  &::after {
    display: block;
    content: '';
    padding-left: 1rem;

    @media (min-width: 992px) {
      display: none;
    }
  }

  @media (min-width: 992px) {
    flex-wrap: wrap;
    overflow: none;
  }
}

</style>
