//Require: jQuery and MomentJS
import moment from 'moment';
import $ from 'jquery';

export function loadDateProgressbar(){

    $(function () {     // waits for DOM
        const now = moment();
        $('.date-progress-bar').each(function () {
            var beginDate = null;
            var maxDate = null;
            const dateFormat = "DD/MM/YYYY";

            let $progressPoints = $('.progress-point');

            $progressPoints.each(function () {
                const pointDate = moment($(this).data('date'), dateFormat);
                beginDate = (!beginDate || pointDate.isBefore(beginDate)) ? pointDate : beginDate;
                maxDate = (!maxDate || pointDate.isAfter(maxDate)) ? pointDate : maxDate;
            });
            beginDate = beginDate.isAfter(now) ? now : beginDate;
            const totalTime = maxDate.diff(beginDate);
            const passedTime = now.diff(beginDate);
            const passedPercent = ((passedTime / totalTime) * 100).toFixed(2);

            if (passedTime < 0) $progressPoints.css('display', 'none'); //hide if not started

            $progressPoints.each(function () {
                const pointDate = moment($(this).data('date'), dateFormat);
                const timeDiff = pointDate.diff(beginDate);
                let pointPercent = ((timeDiff / totalTime) * 100).toFixed(2);
                $(this).css('left', pointPercent + "%");
            });
            // $('.totalProgress').inViewport(function(px){
            //   $(this).css('width', passedPercent+'%');
            // });
            $('.total-progress').css('width', passedPercent + '%');

            // console.info('Time passed: '+passedPercent+'%');
        });
    });

}

