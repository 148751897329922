import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import project from './modules/project'
import post from './modules/post'
import category from './modules/category'
import curator from './modules/curator'
import status from './modules/status'
import mobilizer from './modules/mobilizer'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    project,
    post,
    category,
    curator,
    status,
    mobilizer
  }
})
