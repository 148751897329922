<template>
  <div class="RatingForm">
    <b-form class="RatingForm__form" novalidate :validated="wasValidated" @submit.prevent="handleSubmit">
      <b-form-group label-class="h5" :label-for="`RatingFormInputItem__input-${index}`" :label="question.question" v-for="(question, index) in questions" :key="index">
        <template v-if="question.type === 'number'">
          <b-input :id="`RatingFormInputItem__input-${index}`" type="number" min="0" max="10" required v-model="answers[question.id]"></b-input>
        </template>
        <template v-else-if="question.type === 'radio'">
          <b-form-radio-group :id="`RatingFormInputItem__input-${index}`" :name="`RatingFormInputItem__input-${index}`" required plain v-model="answers[question.id]">
            <b-form-radio v-for="index in 10" :key="index" :value="index">{{ index }}</b-form-radio>
          </b-form-radio-group>
        </template>
        <template v-else-if="question.type === 'textarea'">
          <b-textarea :id="`RatingFormInputItem__input-${index}`" rows="10" required v-model="answers[question.id]"></b-textarea>
        </template>
        <template v-else>
          <b-input :id="`RatingFormInputItem__input-${index}`" type="text" required v-model="answers[question.id]"></b-input>
        </template>
        <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
      </b-form-group>
      <!-- <b-form-row class="mb-7 align-items-center" v-for="(question, index) in questions" :key="index">
        <b-col cols="9" lg="10">
          <label :for="`RatingFormInputItem__input-${index}`" class="h5">{{ question.question }}</label>
        </b-col>
        <b-col cols="3" lg="2">
          <template v-if="question.type === 'number'">
            <b-input class="form-control-slim" :id="`RatingFormInputItem__input-${index}`" type="number" min="0" max="10" required></b-input>
          </template>
          <template v-else-if="question.type === 'textarea'">
            <b-textarea class="form-control-slim" :id="`RatingFormInputItem__input-${index}`" required></b-textarea>
          </template>
          <template v-else>
            <b-input class="form-control-slim" :id="`RatingFormInputItem__input-${index}`" type="text" rows="10" required></b-input>
          </template>
        </b-col>
      </b-form-row> -->
      <b-button class="align-self-lg-center" type="submit" variant="outline-primary">Salvar</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "RatingForm",
  props: {
    questions: Array,
    wasValidated: Boolean
  },
  data() {
    return {
      answers: {}
    }
  },
  methods: {
    handleSubmit(event) {
      this.$emit("formSubmit", event, this.answers);
    }
  },
  mounted() {
    this.questions.forEach(question => {
      this.answers = {
        ...this.answers,
        [`${question.id}`]: ""
      };
    }, this);
  }
};
</script>

<style lang="scss" scoped>
.RatingForm {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
