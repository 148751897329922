import axios from 'axios'
import { getServerURL } from '@/const'

const state = {
  curator: {},
  curators: [],
  loading: false
}

const getters = {}

const actions = {
  getCurators({ commit }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      const currentYear = '2023';
      // const currentYear = (new Date()).getFullYear(); // it breaks when year changes
      // get only the previous years
      axios.get(getServerURL(`/curators?year=${currentYear}&otherYears=false`))
      // axios.get(getServerURL(`/curators`))
        .then(response => {
          const curators = response.data.responseData.curators
          commit('setCurators', curators)
          commit('finishLoading')
          resolve(response)
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  },
  activateCuratorProfile({ commit }, email) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .post(getServerURL('/curator/activate'), email)
        .then(response => {
          if (response.data.responseData.success == true) {
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  }
}

const mutations = {
  startLoading(state) {
    state.loading = true
  },
  finishLoading(state) {
    state.loading = false
  },
  setCurator(state, curator) {
    state.curator = curator
  },
  setCurators(state, curators) {
    state.curators = curators
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
