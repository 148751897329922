<template>
  <div class="TheHeroAside">
    <TheHeroLogin v-show="showLogin && !activateCuratorProfile" @showForgotPassword="handleShowForgotPassword" />
    <TheHeroForgotPassword v-show="showForgotPassword"/>
    <TheHeroActivateCuratorProfile v-if="activateCuratorProfile" />
    <TheHeroDisplay v-show="!showLogin && !showForgotPassword && !activateCuratorProfile" :loading="loading" :custom-data="customData" :view-data="viewData" />
  </div>
</template>

<script>
import TheHeroDisplay from "@/components/TheHeroDisplay"
import TheHeroLogin from "@/components/TheHeroLogin"
import TheHeroForgotPassword from "@/components/TheHeroForgotPassword"
import TheHeroActivateCuratorProfile from "@/components/TheHeroActivateCuratorProfile"

export default {
  name: "TheHeroAside",
  components: {
    TheHeroDisplay,
    TheHeroLogin,
    TheHeroForgotPassword,
    TheHeroActivateCuratorProfile
  },
  props: [
    "loading",
    "show-login",
    "show-forgot-password",
    "custom-data",
    "view-data",
    "activate-curator-profile"
  ],
  methods: {
    handleShowForgotPassword() {
      this.$emit("showForgotPassword");
    }
  }
}
</script>

<style scoped>

</style>
