<meta property="og:description" content="PRÊMIO Pretas Potências: UMA DÉCADA DE CONQUISTAS PARA A ECONOMIA CRIATIVA BRASILEIRA" />
<meta property=”og:image” content=”https://projecthub.com.br/files/media/originals/image.png” />

<template>
  <div v-if="viewData">
    <b-jumbotron bg-variant="dark" fluid>
      <Navbar />
      <b-row>
        <b-col>
          <div class="jumbotron-text">
            <h3>{{ post['Content']['title'] }}</h3>
            <!-- <small>Em: 13 de Junho</small> -->
            <!-- <small>Em: {{ dateFormat(post['Content']['publish_date']) }}</small> -->
          </div>
        </b-col>
      </b-row>
    </b-jumbotron>
    <div class="container">
      <div class="row pt-md-3">
        <div class="col col-md-8 order-md-2 mb-11">
          <div class="row">
            <div class="col">

              <div class="post-content" v-html="post['Content']['content']"></div>

            </div>
          </div>
          <div class="row mb-7">
            <div class="col">
              <small class="font-weight-bolder">{{ dateFormat(post['Content']['publish_date']) }}</small>
            </div>
          </div>
          <!-- <div class="row mb-11">
            <div class="col">
              <div class="social-links">
                <a class="social-link" :href="'https://www.addtoany.com/add_to/facebook?linkurl=https://www.premiobrasilcriativo.com.br' + this.$route.fullPath + '&amp;linkname='" target="_blank"><i class="fab fa-facebook"></i></a>
                <a class="social-link" :href="'https://www.addtoany.com/add_to/whatsapp?linkurl=https://www.premiobrasilcriativo.com.br' + this.$route.fullPath + '&amp;linkname='" target="_blank"><i class="fab fa-whatsapp"></i></a>
                <a class="social-link" :href="'https://www.addtoany.com/add_to/twitter?linkurl=https://www.premiobrasilcriativo.com.br' + this.$route.fullPath + '&amp;linkname='" target="_blank"><i class="fab fa-twitter"></i></a>
                <a class="social-link" :href="'https://www.addtoany.com/add_to/linkedin?linkurl=https://www.premiobrasilcriativo.com.br' + this.$route.fullPath + '&amp;linkname='" target="_blank"><i class="fab fa-linkedin"></i></a>
              </div>
            </div>
          </div> -->
          <div v-if="viewData.related.length" class="row">
            <div class="col">
              <!--<h3 class="mb-7">Relacionadas</h3>-->
              <h3 class="mb-7">Relacionadas</h3>

              <div class="row mb-7" v-for="(related, index) in viewData.related" :key="index">
                <div class="col">
                  <div class="card card-horizontal">
                    <router-link
                      :to="{ name: 'postDetail', params: {postSlug: related['Content']['slug']} }"
                    >
                      <div class="row no-gutters">
                        <div class="col col-4">
                          <img
                            :src="related['FeaturedImage']['croppedImg'] ? related['FeaturedImage']['croppedImg'].url : ''"
                            class="card-img"
                            alt
                          />
                        </div>
                        <div class="col col-8">
                          <div class="card-body">
                            <h3 class="card-title-news">{{ related['Content']['title'] }}</h3>
                            <div class="card-text-news">
                              <small>{{ dateFormat(related['Content']['publish_date']) }}</small>
                            </div>
                            <!-- <div class="card-text-news"><small>Por: Fulano</small></div> -->
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 order-md-1">
        <div class="side-box" style="position: fixed;">
          <!-- <a class="btn btn-outline-primary d-block mb-7" href="#">Acesse nossa newsletter</a> -->
          <!-- <div class="mb-7 text-uppercase">
            <span class="font-weight-bolder">Premio Pretas Potências nas redes sociais</span>
          </div> -->
          <div class="social-links mb-7">
            <a class="social-link" :href="viewConfig.general.facebook" v-if="viewConfig.general.facebook">
              <i class="fab fa-facebook"></i>
            </a>
            <a class="social-link" :href="viewConfig.general.twitter" v-if="viewConfig.general.twitter">
              <i class="fab fa-twitter"></i>
            </a>
            <a class="social-link" :href="viewConfig.general.instagram" v-if="viewConfig.general.instagram">
              <i class="fab fa-instagram"></i>
            </a>
            <a class="social-link" :href="viewConfig.general.linkedin" v-if="viewConfig.general.linkedin">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
          <div class="mb-7 text-uppercase">
            <!-- <span class="font-weight-bolder">@PremioBrasilCriativo no Instagram</span> -->
          </div>
          <div>
            <!-- <h3>[ THUMBS ]</h3> -->
            <!-- <router-link class="btn btn-outline-primary btn-home text-uppercase d-block mb-10" to="/registrar">Inscreva-se</router-link> -->
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <!--<div v-else>-->
  <!--<h4>Carregando...</h4>-->
  <!--</div>-->
</template>

<script async src="https://static.addtoany.com/menu/page.js"></script>

<script>
import { getServerURL } from "@/const";
import { safe } from "@/helpers/utils";
import axios from "axios";
import moment from "moment";
import Navbar from "@/components/Navbar";

const tempTitle = "Notícia";

export default {
  name: "PostDetail",
  components: {
    Navbar
  },
  metaInfo() {
    const img = safe(safe(safe(this.post)['FeaturedImage'])['croppedImg']).url;
    const description = safe(safe(this.post)['Content'])['meta_description'];
    const  metaTitle = safe(safe(this.post)['Content'])['meta_title'] || safe(safe(this.post)['Content'])['title'];
    return {
      title: this.post ? this.post["Content"]["title"] : tempTitle,
      meta: [
        { vmid: 'og:type', property: 'og:type', content: 'article' },
        { vmid: 'og:title', property: 'og:title', content: metaTitle },
        { vmid: 'og:image', property: 'og:image', content: img },
        { vmid: 'og:description', property: 'og:description', content: description },
        // {property: 'article:author', content: ''},
        // {property: 'article:section', content: ''},
        // {property: 'article:tag', content: ''},
        // { vmid: 'article:modified_time', property: 'article:modified_time', content: ''},
        { vmid: 'article:published_time', property: 'article:published_time', content: safe(safe(this.post)['Content'])['publish_date'] },
        { vmid: 'twitter:title', name: 'twitter:title', content: metaTitle },
        { vmid: 'twitter:description', name: 'twitter:description', content: description },
        { vmid: 'twitter:image', name: 'twitter:image', content: img },
        { vmid: 'itemName', itemprop: 'name', content: metaTitle },
        { vmid: 'itemDescription', itemprop: 'description', content: description },
        { vmid: 'itemImage', itemprop: 'image', content: img }
      ]
    };
  },
  props: { postSlug: String },
  data() {
    return {
      loading: true,
      errored: false,
      viewData: null,
      viewConfig: null,
      post: null,
      postTitle: null
    };
  },
  mounted() {
    axios
      .get(getServerURL("/posts/:slug").replace(":slug", this.postSlug))
      .then(response => {
        this.viewData = response.data.responseData;
        this.post = response.data.responseData.data;
        this.viewConfig = response.data.responseData.viewConfig || {general:{}};
      })
      .catch(() => {
        // console.error(error);
        this.errored = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    dateFormat(dateStr) {
      return moment(dateStr).format("DD/MM/YYYY HH:mm"); // e.g. 24/05/2019 10:15
    }
  }
};
</script>

<style>
.post-content img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
</style>
