import axios from "axios";
import { getServerURL } from "@/const";

function getQuestions() {
  return new Promise((resolve, reject) => {
    axios
      .get(getServerURL("/ratings/questions"))
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function create(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(getServerURL("/ratings"), data)
      .then(response => {
        if (response.data.responseData.success == true) {
          resolve(response);
        }
      })
      .catch(error => reject(error));
  });
}

export default {
  create,
  getQuestions
};
