import axios from 'axios'
import { getServerURL } from '@/const'

const state = {
  project: {
    country_name: 'Brasil',
    sent: false
  },
  projects: [],
  loading: false
}

const getters = {
  unsentProjects: state => state.projects.filter(project => project.status == 2)
}

const actions = {
  getAllProjects ({ commit }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL('/curators/projects'))
        .then(response => {
          if (response.data.responseData.success == true) {
            const projects = response.data.responseData.projects
            commit('setProjects', projects)
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  },
  getUserProjects({ commit, getters }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL('/user/projects'))
        .then(response => {
          if (response.data.responseData.success == true) {
            const projects = response.data.responseData.projects
            commit('setProjects', projects)
            if (getters.unsentProjects.length) {
              commit('recoverUnsentProject', getters.unsentProjects[0])
            } else {
              commit('clearProject')
            }
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  },
  saveProject({ commit, state }, params) {

    commit('startLoading')

    // objeto FormData que sera enviado
    const formData = new FormData()

    // adicao de arquivos para upload deve ser separado...
    if (state.project.coverImageFile) formData.append('coverImageFile', state.project.coverImageFile)
    if (state.project.ownerDocumentFile) formData.append('ownerDocumentFile', state.project.ownerDocumentFile)
    if (state.project.picturesFile && state.project.picturesFile.length) {
      state.project.picturesFile.forEach(imageFile => {
        formData.append('picturesFile[]', imageFile)
      });
    }

    if (state.project.id) {

      if (params && params.send) {
        commit('prepareToSendProject')
      }

      // ...dos dados restantes do formulario enviados como JSON
      formData.append('formData', JSON.stringify(state.project))

      return new Promise((resolve, reject) => {
        // versão com mock
        // axios.post(getServerURL('/user/project/edit'), formData)
        axios
          .post(getServerURL('/user/projects/:id').replace(":id", state.project.id), formData)
          .then(response => {
            if (
              response.data.responseData.success === true ||
              response.data.responseData.success === 'true'
            ) {
              if (params && params.send) {
                commit('clearProject')
              } else {
                const project = response.data.responseData.project
                commit('setProject', project)
              }
              commit('finishLoading')
              resolve(response)
            }
          })
          .catch(error => {
            if (params && params.send) {
              commit('rollbackSentProject')
            }
            commit('finishLoading')
            reject(error)
          })
      })
    } else {
      // ...dos dados restantes do formulario enviados como JSON
      formData.append('formData', JSON.stringify(state.project))

      return new Promise((resolve, reject) => {
        axios
          .post(getServerURL('/user/projects', '201'), formData)
          .then(response => {
            if (
              response.data.responseData.success === true ||
              response.data.responseData.success === 'true'
            ) {
              const project = response.data.responseData.project
              commit('setProject', project)
              commit('finishLoading')
              resolve(response)
            }
          })
          .catch(error => {
            commit('finishLoading')
            reject(error)
          })
      })
    }
  },
  removeProjectFile({ commit, state }, filename) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .delete(
          getServerURL('/user/projects/:id/file/:filename')
            .replace(':id', state.project.id)
            .replace(':filename', filename)
        )
        .then(response => {
          if (response.data.responseData.success == true) {
            commit('setProject', {
              picturesUrls: state.project.picturesUrls.filter(pictureUrl => !pictureUrl.includes(filename))
            })
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  }
}

const mutations = {
  startLoading(state) {
    state.loading = true
  },
  finishLoading(state) {
    state.loading = false
  },
  setProjects(state, projects) {
    state.projects = projects
  },
  setProject(state, project) {
    state.project = {
      ...state.project,
      ...project
    }
  },
  recoverUnsentProject(state, project) {
    state.project = {
      ...state.project,
      ...project,
      categoryId: project.categoryIds[0]
    }
  },
  prepareToSendProject(state) {
    state.project.sent = true
  },
  rollbackSentProject(state) {
    state.project.sent = false
  },
  clearProject(state) {
    state.project = {
      country_name: 'Brasil',
      sent: false
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
