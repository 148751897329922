<template>
  <div class="BaseSplashScreen">
    <template v-if="variant === 'success'">
      <i class="fas fa-7x fa-check-circle text-success mb-11"></i>
    </template>
    <template v-else-if="variant === 'danger'">
      <i class="fas fa-7x fa-times-circle text-danger mb-11"></i>
    </template>
    <template v-else-if="variant === 'warning'">
      <i class="fas fa-7x fa-exclamation-circle text-warning mb-11"></i>
    </template>
    <h2 class="h4 mb-9">{{ title }}</h2>
    <p>{{ text }}</p>
    <b-button variant="outline-primary" @click="handleClick" v-if="!hideBtn">
      {{ btnText }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: "BaseSplashScreen",
  props: {
    variant: String,
    title: String,
    text: String,
    btnText: String,
    hideBtn: Boolean
  },
  methods: {
    handleClick() {
      this.$emit('btnClicked');
    }
  }
};
</script>

<style lang="scss" scoped>
.BaseSplashScreen {
  text-align: center;
}
</style>
