import axios from 'axios'
import router from '@/router'

const authKey = 'X-Authorization'

function setup() {
  if (localStorage.getItem('token')) {
    axios.defaults.headers.common[authKey] = 'Bearer ' + localStorage.getItem('token')
  }
  axios.interceptors.response.use(response => response, err => {
    if (err.response.status === 401) {
      if (err.response.data.responseData.errorType == 'expired_token') {
        router.push({ name: 'sessionExpired' })
      }
    }
    return Promise.reject(err);
  })
}

export default {
  setup,
  authKey
}
