import axios from "axios";
// import { getServerURL } from "@/const"; // MOCK

function save(data) {
  const formattedFormData = new FormData();

  for (let property in data) {
    formattedFormData.append(`${property}`, data[property]);
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://script.google.com/macros/s/AKfycbzLXl3FlqavOgJS1MxvSF1fvGEWaV0M4l4UjpH6KIFcbOadO-A/exec",
        formattedFormData
      )
      // .post(getServerURL("/recommendations"), formattedFormData) // MOCK
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  save
};
