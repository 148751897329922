<template>
  <div class="Invite">
    <BaseJumbotron title="Convite para cerimônia do Prêmio Brasil Criativo 2019" />
    <BaseContainer>
      <div class="InviteContent" v-show="!showSuccess">
        <article class="InviteContent__description mb-9">
          <h2 class="text-center text-lg-left h3 mb-7">Olá criativo(a)!</h2>
          <p>Quer participar da cerimônia de premiação do Prêmio Brasil Criativo 2019? Então não esqueça de preencher o formulário abaixo para confirmar a sua presença!</p>
          <p>Todos juntos por um Brasil mais Criativo!</p>
          <h3 class="text-center text-lg-left h4 mb-7">Local do evento</h3>
          <p>
            <strong>Evento:</strong> Cerimônia de Premiação - Prêmio Brasil Criativo<br>
            <strong>Data:</strong> 30/11/19, às 18h30 - Auditório - Festival Pixel Show<br>
            <strong>Endereço:</strong> Espaço Promagno - Av. Profa. Ida Kolb, 513, Jardim das Laranjeiras, São Paulo – SP
          </p>
        </article>
        <InviteForm class="mb-7" :loading="loading" @formSubmit="handleSubmit"/>
      </div>
      <BaseSpinner v-show="loading"/>
      <b-alert fade dismissible variant="danger" class="mb-0" v-model="showError">
        <h5 class="h5">Erro!</h5>
        <p :class="{ 'mb-0': !this.error }">Ocorreu um problema ao salvar. Tente novamente ou entre em contato conosco.</p>
        <p class="mb-0" v-if="this.error">Motivo: {{ this.error }}</p>
      </b-alert>
      <BaseSplashScreen hide-btn variant="success" title="Presença confirmada com sucesso!" v-show="showSuccess"/>
    </BaseContainer>
  </div>
</template>

<script>
import InviteService from "@/api/services/invite";
import BaseJumbotron from "@/components/BaseJumbotron";
import BaseContainer from "@/components/BaseContainer";
import BaseSpinner from "@/components/BaseSpinner";
import BaseSplashScreen from "@/components/BaseSplashScreen";
import InviteForm from "@/components/InviteForm";

export default {
  name: "Invite",
  components: {
    BaseJumbotron,
    BaseContainer,
    BaseSpinner,
    BaseSplashScreen,
    InviteForm
  },
  metaInfo() {
    return {
      title: "Convite para cerimônia"
    }
  },
  data() {
    return {
      origin: "",
      campaigns: {
        "Z2VuZXJ": "Geral",
        "cGVkcm8": "Pedro Porto",
        "bGVvLWJ": "Leo Bianchine",
        "Y2FldGF": "Caetana Franarin",
        "YWxleC1": "Alex Lima (norte)",
        "YW5hLXN": "ANA SUELEN PISETTA",
        "am9zZS1": "José Carlos Aronchi",
        "YWxleC2": "Alex Lima (sul)",
        "ZGFueS1": "Dany Carvalho",
        "bWFyY2l": "Márcia Andrade Carmo de Azevedo",
        "YWxleGF": "Alexandre Santos",
        "cGF1bGE": "Paula Bruehmueller",
        "ZWRpbG1": "Edilma Lemanhê",
        "cGF1bGF": "Paula Brandão",
        "bGlnaWE": "Lígia Fontes",
        "Y2Fyb2x": "carolina herszenhut",
        "cGF1bG8": "Paulo Rogério Nunes",
        "ZGVjaW8": "Decio Coutinho",
        "anVuaW9": "Junior Medeiros",
        "bWF1cml": "MAURICIO KINOSHITA",
        "YW5kcmU": "André Lira",
        "YWxpbmU": "Aline Galvão Nazato",
        "a2FyZW4": "Karen Rubem",
        "d2F5bmV": "Wayner Bechelli",
        "dmFuZXN": "Vanessa Kukul",
        "bHVpcy1": "Luis Leigue",
        "bGFyYS1": "Lara dos Anjos",
        "Z2VybWF": "Germana Uchoa",
        "ZHVkdS1": "Dudu Magalhães",
        "bGFyYS2": "Lara Lages",
        "dGluYS1": "Tina Vasconcelos",
      },
      showSuccess: false,
      showError: false,
      loading: false,
      error: ""
    }
  },
  methods: {
    handleSubmit(formData) {
      this.loading = true;
      formData = {
        ...formData,
        origin: this.campaigns[this.origin]
      };
      InviteService
        .save(formData)
        .then(() => {
          this.showSuccess = true;
        })
        .catch(({ response: { data: { error } } }) => {
          this.showError = true;
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    if (
      !this.$route.query.o ||
      !Object.keys(this.campaigns).includes(this.$route.query.o)
    ) {
      this.$router.push({ name: "home" });
    } else {
      this.origin = this.$route.query.o;
    }
  }
};
</script>

<style lang="scss">
.Invite {
  margin-bottom: 150px;
}
</style>
