<template>
  <div class="ProjectList">
    <div role="tablist" class="accordion faq mb-11">
      <div v-if="isCurator" >
        <template v-if="categories.length">
          <template v-for="(category_name, index) in categories">
            <template v-if="filterProjectsByCategory(category_name).length">
              <b-card no-body class="faq-card border-0" :key="index">
                <b-card-header header-tag="header" class="faq-card-header project-card-header mb-0" role="tab">
                  <b-link class="faq-card-header-button" href="#" v-b-toggle="'accordion' + index">
                    <div class="faq-card-header-title">{{ category_name }}</div>
                    <div class="faq-card-header-addon">
                      <span class="fas fa-fw fa-arrow-circle-up"></span>
                    </div>
                  </b-link>
                </b-card-header>
                <b-collapse class="faq-card-collapse border-0 m-0" :id="'accordion' + index" role="tabpanel" :visible="categories.length === 1">
                  <b-card-body class="px-0 pb-0">
                    <b-row class="mx-n3" no-gutters>
                      <b-col class="px-3" cols="6" md="4" lg="3" v-for="project in filterProjectsByCategory(category_name)" :key="project.id">
                        <b-link @click="handleClick(project)">
                          <div class="card card-horizontal border-0 mb-4">
                            <img v-if="project.coverImageUrl" class="card-img" alt="Imagem de capa do projeto" :src="project.coverImageUrl"/>
                            <div class="card-img-overlay justify-content-end align-items-start text-left p-3">
                              <h3 class="h5">{{ project.name }}</h3>
                            </div>
                          </div>
                        </b-link>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </template>
          </template>
        </template>
      </div>
      <div v-else>
        <template v-if="statusList.length">
          <template v-for="(status_name, index) in statusList">
            <!-- Filtro para NÃO exibir projetos enviados -->
            <template v-if="status_name.toUpperCase() !== 'DADOS PENDENTES'">
              <template v-if="filterProjectsByStatus(status_name).length">
                <b-card no-body class="faq-card border-0" :key="index">
                  <b-card-header header-tag="header" class="faq-card-header project-card-header mb-0" role="tab">
                    <b-link class="faq-card-header-button" href="#" v-b-toggle="'accordion' + index">
                      <div class="faq-card-header-title">{{ status_name }}</div>
                      <div class="faq-card-header-addon">
                        <span class="fas fa-fw fa-arrow-circle-up"></span>
                      </div>
                    </b-link>
                  </b-card-header>
                  <b-collapse class="faq-card-collapse border-0 m-0" :id="'accordion' + index" role="tabpanel" :visible="statusList.length === 1">
                    <b-card-body class="px-0 pb-0">
                      <b-row class="mx-n3" no-gutters>
                        <b-col class="px-3" cols="6" md="4" lg="3" v-for="project in filterProjectsByStatus(status_name)" :key="project.id">
                          <b-link @click="handleClick(project)">
                            <div class="card card-horizontal border-0 mb-4">
                              <img v-if="project.coverImageUrl" class="card-img" alt="Imagem de capa do projeto" :src="project.coverImageUrl"/>
                              <div class="card-img-overlay justify-content-end align-items-start text-left p-3">
                                <h3 class="h5">{{ project.name }}</h3>
                              </div>
                            </div>
                          </b-link>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </template>
            </template>
          </template>
        </template>
      </div>
      <!-- <template v-else>
        <b-card no-body class="faq-card border-0">
          <b-card-header header-tag="header" class="faq-card-header project-card-header mb-0" role="tab">
            <b-link class="faq-card-header-button" href="#" v-b-toggle="'accordionEmpty'">
              <div class="faq-card-header-title">{{ emptyTitle }}</div>
              <div class="faq-card-header-addon">
                <span class="fas fa-fw fa-arrow-circle-up"></span>
              </div>
            </b-link>
          </b-card-header>
          <b-collapse class="faq-card-collapse border-0 m-0" :id="'accordionEmpty'" role="tabpanel" visible>
            <b-card-body class="px-0 pb-0">
              <b-row>
                <b-col>
                  {{ emptyMessage }}
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </template> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "ProjectList",
  props: {
    emptyTitle: String,
    emptyMessage: String,
    projects: Array,
    statusList: Array,
    categories: Array,
    loading: Boolean
  },
  data() {
    return {}
  },
  computed: {
    ...mapState("user", {
      user: state => state.user,
      loadingUser: state => state.loading
    }),
    ...mapGetters("user", ["isCurator"]),
  },
  methods: {
    filterProjectsByStatus (status_name) {
      return this.projects.filter(project => project.status_name === status_name);
    },
    filterProjectsByCategory (category_name) {
      return this.projects.filter(project => project.category_name === category_name);
    },
    //filterProjectsRated (category_name) {
    //  return this.projects.filter(project => project.category_name === category_name);
    //},
    handleClick(item) {
      this.$emit('itemClicked', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.project-card {
  &-header {
    display: block;
    margin-bottom: 0;
    border: 0.21875rem solid #f2f2f2;
  }
}
</style>
