<template>
  <div class="TheHeroForgotPassword">
    <form
      novalidate
      :class="{ 'was-validated': wasForgotPasswordValidated }"
      class="TheHeroForgotPassword__form"
      @submit.prevent="forgotPassword"
    >
      <h5 class="text-center h5 mb-9">Digite aqui o seu e-mail</h5>
      <div class="input-group mb-9">
        <div class="input-group-prepend">
          <span class="input-group-text input-group-text-slim">
            <i class="far fa-fw fa-envelope"></i>
          </span>
        </div>
        <label for="emailForgotPassword" class="sr-only">Email</label>
        <input
          type="email"
          class="form-control form-control-slim"
          placeholder="email"
          id="emailForgotPassword"
          v-model="emailForgotPassword"
          minlength="10"
          required
        />
        <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
      </div>
      <input type="submit" class="btn btn-outline-primary mb-9" value="enviar" />
      <BaseSpinner v-show="loading" />
      <div v-show="showSuccess" class="align-items-center">
        <b-alert fade dismissible variant="success" class="mb-0" v-model="showSuccess">
          <h5 class="h5">Successo!</h5>
          <p class="mb-0">Um email foi enviado para {{ emailForgotPassword }} contendo as instruções para a recuperação da sua senha.</p>
        </b-alert>
      </div>
      <b-alert fade dismissible variant="danger" class="mb-0" v-model="showFailed">
        <h5 class="h5">Erro!</h5>
        <p class="mb-0">{{ message }}</p>
        <!-- <b-link :to="{ name: 'entrepreneurSignUp' }" variant="outline-primary" class="btn btn-outline-primary">registrar</b-link> -->
      </b-alert>
    </form>
  </div>
</template>

<script>
import BaseSpinner from "@/components/BaseSpinner"

export default {
  name: "TheHeroForgotPassword",
  components: {
    BaseSpinner
  },
  data() {
    return {
      emailForgotPassword: "",
      wasForgotPasswordValidated: false,
      loading: false,
      message: "",
      showSuccess: false,
      showFailed: false
    }
  },
  methods: {
    forgotPassword(e) {
      this.wasForgotPasswordValidated = true
      if(e.target.checkValidity()) {
        this.loading = true
        this.$store
          .dispatch("user/forgotPassword", {
            email: this.emailForgotPassword
          })
          .then(() => {
              this.showSuccess = true
              this.showFailed = false
          })
          .catch(({ response: { data: { responseData } } }) => {
            this.message = responseData.msg
            this.showFailed = true
            this.showSuccess = false
          })
          .catch(() => {
            this.message = "Serviço indisponível.";
            this.showFailed = true;
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.TheHeroForgotPassword__form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 20vh;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  @media (min-width: 768px) {
    padding-top: 14.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 992px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 1200px) {
    padding-right: 2.5rem;
  }
}

.form-control-slim {
  font-size: 1rem;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
}
</style>
