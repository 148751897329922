<template>
  <!--<title><?= $title ?></title>-->
  <!-- <meta name="description" content="<?= $description ?>"> -->
  <!--<meta name="keywords" content="<?= implode(', ', $keywords) ?>">-->
  <!--<meta name="revisit-after" content="<?= $revisit_after ?>">-->

    <full-page ref="fullpage" :options="fullpageOptions" id="fullpage">

      <div data-anchor="inicio" class="section"> 
        <div class="row mx-0 min-vh-100">
          <div class="hero col-md-7 col-lg-8 fullscreen-bg">
            <!-- <video class="fullscreen-bg__video" data-autoplay muted loop>
              <source src="@/assets/videos/hero-bg.mp4" type="video/mp4">
            </video> -->
            <img class="fullscreen-bg__video" src="@/assets/images/Arte-home.png">
            <!-- <nav class="nav align-items-start"> -->
            <nav class="nav flex-column align-items-start">
              <!-- <a class="h2" href="#o-premio">O prêmio</a> -->
              <!-- <a class="h2" href="#categorias">Categorias</a> -->
              <!-- <a class="h2" href="#jurados">Jurados</a> -->
              <!-- <a class="h2" href="#comunidade">Comunidade</a> -->
              <!-- <a class="h2" href="#mobilizadores">Mobilizadores</a> -->
              <!-- <a class="h2" href="#etapas">Etapas</a> -->
              <!-- <a class="h2" href="#noticias" v-if="loading || viewData.posts.length">Notícias</a> -->
               <!-- <a class="h2" href="#regulamento">Regulamento</a> -->
              <!-- <a class="h2" href="#resultados">Resultados</a> -->
              <!-- <p class="h2 mt-6">Inscrições encerradas!</p> -->
              <!-- <p class="h2 mt-6">FAÇA PARTE DA FESTA! <br><br>
                                  24/JAN 18H00 <br><br>
                                  Centro Cultural São Paulo</p> -->

              <!-- <div class="row d-flex align-items-end mt-6 mb-6 ml-1" style="height: 100px;">
                <a class="btn btn-outline-primary btn-home" href="//premiobrasilcriativo.com.br/blog/premio-brasil-criativo-apresenta-vencedores-da-sua-edicao-2023">
                  Conheça os vencedores
                </a>
              </div>  -->
              
              <!-- <p class="h3 mt-6 pl-3">PARTICIPE DA CERIMÔNIA DE PREMIAÇÃO EM SÃO PAULO</p> -->

              <template v-if="customData.home.countdown_date">
                <div class="mt-6 pl-3">
                  <template v-if="getTime(customData.home.countdown_date) <= 0">
                    <h3 style="color: #ff0061;">{{ customData.home.countdown_finish }}</h3>
                  </template>
                  <template v-else>
                    <countdown :time="getTime(customData.home.countdown_date)" tag="h3">
                      <template slot-scope="props" class="text-uppercase">
                        <h3 style="color: #ff0061;">
                        {{ customData.home.countdown_label }} {{props.days}} dias, {{props.hours}} horas e {{props.minutes}} minutos
                        </h3>
                      </template>
                    </countdown>
                  </template>
                </div>
              </template>

              
            </nav>
            <div class="sponsor">
              <img class="sponsor-img text-center" src="@/assets/images/barra-logos.png" alt="Chancelas">
            </div>
            <!-- <div class="sponsors">
              <div class="sponsor">
                <figure class="figure">
                  <a href="" target="_blank">
                    <img class="sponsor-img" src="@/assets/images/arte-lei-de-incentivo-a-cultura.png" alt="Arte da Lei de Incentivo à Cultura">
                  </a>
                </figure>
              </div>
              <div class="sponsor">
               <figure class="figure">
                  <figcaption class="sponsor-caption align-self-start">Patrocínio</figcaption>
                  <div class="mx-n4">
                    <a href="//www.3m.com.br/" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/logo-3m.png" alt="Logo da 3M">
                    </a>
                    <a href="//sebrae.com.br/" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/logo-sebrae.png" alt="Sebrae">
                    </a>
                  </div>
                </figure>
              </div>

              <div class="sponsor">
                <figure class="figure">
                  <figcaption class="sponsor-caption align-self-start">Apoio</figcaption>
                  <a href="//www.progen.com.br/" target="_blank">
                    <img class="sponsor-img" src="@/assets/images/logo-progen-white.png" alt="Logo do Mercado Livre">
                  </a>
                </figure>
              </div>
              <div class="sponsor">
                <figure class="figure">
                  <figcaption class="sponsor-caption">Organização</figcaption>
                  <a href="//elo3.com.br/" target="_blank">
                    <img class="sponsor-img px-4" src="@/assets/images/logo_elo3_rosa.png" alt="Logo elo 3">
                  </a>
                </figure>
              </div>
             
             
              <div class="sponsor">
                <figure class="figure">
                  <figcaption class="sponsor-caption align-self-start">Realização</figcaption>
                  <div class="mx-n4">
                    <a href="//pt.wikipedia.org/wiki/Organiza%C3%A7%C3%A3o_Mundial_da_Criatividade" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/logo-wco-white.png" alt="World Creativity Organization">
                    </a>
                    <a href="//www.gov.br/turismo/pt-br" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/secretaria-e-turismo.png" alt="Arte Pátria Amada Brasil">
                    </a>
                  </div>
                </figure>
              </div>
            </div> -->
          </div>
          <TheHeroMobile :loading="loading" :custom-data="customData" :view-data="viewData" :login="showLogin" :activate-curator-profile="showActivateCuratorProfile"/>
        </div>
      </div>

      <!-- VIDEO -->
      <!-- <div data-anchor="video" class="container mt-6 section min-vh-100">
          <div class="mx-auto video-container">
            <iframe src="https://www.youtube.com/embed/NfuiJDpfcn4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div> -->

       <div data-anchor="o-premio" class="section">
        <div class="row mx-0 min-vh-100">
          <div class="about-content col-lg-7">
            <div class="about-copy">
              <h1 class="mb-11">O prêmio</h1>
              <div v-html="customData.home.about_text" v-if="customData.home && customData.home.about_text"></div>
            </div>
            <div class="social-links d-none d-lg-flex" v-if="customData.general && (customData.general.facebook || customData.general.twitter || customData.general.instagram || customData.general.linkedin)">
              <a v-if="customData.general.facebook" class="social-link" :href="customData.general.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
              <a v-if="customData.general.twitter" class="social-link" :href="customData.general.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
              <a v-if="customData.general.instagram" class="social-link" :href="customData.general.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
              <a v-if="customData.general.linkedin" class="social-link" :href="customData.general.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            </div>
          </div>
          <div class="about-img col-lg-5 text-right"></div>
        </div>
      </div> 

      <div data-anchor="premio-pretas-potencias" class="section about-content">
        <h2 class="h1">Prêmio Pretas Potências</h2>
        <div class="row mx-0">
          <div class="about-content col-lg-4">
            <div class="card mb-7 sub-category">
              <img src="@/assets/images/premio-pretas-potencias.png" class="card-img category-img" alt="capa categoria">
            </div>
          </div>
          <div class="mt-10 col-lg-6">
            <div class="social-links mb-6 d-none d-lg-flex" >
              <a v-if="customData.general.facebook" class="social-link" :href="customData.general.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
              <a v-if="customData.general.twitter" class="social-link" :href="customData.general.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
              <a v-if="customData.general.instagram" class="social-link" :href="customData.general.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
              <a v-if="customData.general.linkedin" class="social-link" :href="customData.general.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            </div>
            <h2 class="mb-12">Vai premiar 150 iniciativas:</h2>
            <h3>100 (cem)</h3>
            <p>trabalhos artísticos realizados por artistas individuais.</p>
            <h3>50 (cinquenta)</h3>
            <p>por grupos ou coletivos artísticos.</p>
            <p>Áreas: Música Instrumental, Artes Cênicas, Artes visuais, Literatura, Audiovisual e Patrimônio imaterial. </p>
            <p>As propostas serão inscritas via edital e avaliadas por uma comissão curatorial.</p>

          </div>
        </div>
      </div> 

      <!-- <div data-anchor="lucas-foster" class="section about-content">
        <h2 class="h1">Lucas Foster</h2>
        <div class="row mx-0 ">
          <div class="about-content col-lg-4">
            <div class="card mb-7 sub-category">
              <img src="@/assets/images/lucas-foster.jpg" class="card-img card-img-translucid category-img" alt="capa categoria">
            </div>
          </div>
          <div class="mt-10 col-lg-6">
            <div class="social-links mb-6 d-none d-lg-flex" >
              <a v-if="customData.general.facebook" class="social-link" :href="customData.general.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
              <a v-if="customData.general.twitter" class="social-link" :href="customData.general.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
              <a v-if="customData.general.instagram" class="social-link" :href="customData.general.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
              <a v-if="customData.general.linkedin" class="social-link" :href="customData.general.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            </div>
            <h3>IDEALIZADOR E CURADOR DO PRÊMIO BRASIL CRIATIVO</h3>
            <p>Lucas Foster é psicólogo e idealizador do Prêmio Brasil Criativo, chancelado pelo Ministério da Cultura em 2014 como a premiação oficial da Economia Criativa brasileira e apoiado pela UNESCO em 2016.</p>
            <p>Diretor-geral da World Creativity Organization, organização privada de atuação global, com o propósito de aumentar a conscientização de indivíduos, organizações e governos sobre o valor da criatividade como matéria-prima para a solução de problemas e, por extensão, no desenvolvimento social, tecnológico e econômico sustentáveis em nosso século.</p>
            <p>O cumprimento dos seus objetivos se dá através do Creative Leadership Certification Program, do World Creativity Day, World Creativity Festival  e do Prêmio Brasil Criativo.</p>

          </div>
        </div>
      </div>  -->

      <div data-anchor="categorias" class="section">
        <div class="row mx-0 min-vh-100">
          <div class="col p-11">
            <h2 class="h1 mb-11">Categorias</h2>
            <template v-if="viewData.categories && viewData.categories.length">
            <div class="row flex-nowrap no-gutters mx-n11 mx-lg-n2 category" style="overflow-x: auto; overflow-y: hidden;">
              <div class="pl-9 pl-lg-0"></div>
              <div v-for="(category, index) in viewData.categories" :key="index" class="col-10 col-lg-3 px-2">
                <div class="card mb-7 sub-category">
                  <img v-if="category.Image" :src="safe(category.Image.croppedImg).url" class="card-img category-img" alt="capa categoria">
                  <div class="card-img-overlay card-img-overlay-hover">
                    <div class="card-text" v-html="category.description">
                      {{ category.description }}
                    </div>
                  </div>
                </div>
                <h4 class="h4">{{ category.name }}</h4>
              </div>
              <!-- Card for Startup -->
              <!-- <div class="col-10 col-lg-3 px-2">
                <div class="card mb-7 sub-category">
                  <img src="//projecthub.com.br/files/c/1675/600-450-1-0.jpg" class="card-img card-img-translucid category-img" alt="capa categoria">
                  <div class="card-img-overlay card-img-overlay-hover">
                    <div class="card-text">
                      <a href="//www.startvc.com.br" target="_blank">
                        StartUps é uma nova categoria especial da 4ª edição do Prêmio Brasil Criativo apresentada em aliança com a STARTVC.
                        Para se inscrever nesta categoria: 
                        Para escolher a categoria StartUps, acesse o site da STARTVC (www.startvc.com.br).
                        Clique em um botão para se inscrever no processo de seletivo para aceleração.
                        Quando o formulário abrir: preencha todos os campos necessários e MARQUE A OPÇÃO “Quero participar da 4ª edição do Prêmio Brasil Criativo, categoria especial StartUps.”
                      </a>
                    </div>
                  </div>
                </div>
                <h4 class="h4">Startups</h4>
              </div> -->
              <div class="pl-9 pl-lg-0"></div>
            </div>
            </template>
          </div>
        </div>
      </div>

      <Curators :curator-slug="curatorSlug" />

      <!-- <Community /> -->

      <!-- <Mobilizers :mobilizer-slug="mobilizerSlug" /> -->

      <div data-anchor="etapas" class="section">
        <div class="row mx-0 min-vh-100">
          <div class="col stages">
            <h2 class="h1 text-center text-lg-left">Etapas</h2>
            <div class="stages-widget">
              <DateProgressBar ref="dateProgress"></DateProgressBar>
              <!-- <img src="@/assets/images/arte-estreia-documentario-pbc.png"> -->
            </div>
          </div>
        </div>
      </div>

      <div data-anchor="noticias" class="section" v-if="loading || viewData.posts.length">
        <div class="row mx-0 min-vh-100">
          <div class="col news">
            <h2 class="h1 mb-11">Notícias</h2>
            <div class="news-widget">
              <div class="news-cards">
                <div v-if="viewData.posts[0]" class="card card-horizontal">
                  <router-link :to="{ name: 'postDetail', params: { postSlug: viewData.posts[0]['Content']['slug']} }">
                    
                    <div class="row no-gutters">
                      <div class="col-4">
                        <img class="card-img" alt="capa noticia" :src="viewData.posts[0]['FeaturedImage']['croppedImg'].url">
                      </div>
                      <div class="col-8">
                        <div class="card-body">
                          <h4 class="card-title-news">{{ viewData.posts[0]['Content']['title']}}</h4>
                          <div class="card-text-news"><small>{{ dateFormat(viewData.posts[0]['Content']['publish_date']) }}</small></div>
                          
                          
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div v-if="viewData.posts[1]" class="card card-horizontal">
                  <router-link :to="{ name: 'postDetail', params: {postSlug: viewData.posts[1]['Content']['slug']} }">
                    <img class="card-img" alt="capa noticia" :src="viewData.posts[1].FeaturedImage.croppedImg.url">
                    <div class="card-img-overlay">
                      <h4 class="card-title-news">{{ viewData.posts[1]['Content']['title'] }}</h4>
                      <div class="card-text-news"><small>{{ dateFormat(viewData.posts[1].Content.publish_date) }}</small></div>
                      
                    </div>
                  </router-link>
                </div>
                <div v-if="viewData.posts[2]" class="card card-horizontal">
                  <router-link :to="{ name: 'postDetail', params: {postSlug: viewData.posts[2].Content.slug} }">
                    <img class="card-img" alt="capa noticia" :src="viewData.posts[2].FeaturedImage.croppedImg.url">
                    <div class="card-img-overlay">
                      <h4 class="card-title-news">{{ viewData.posts[2]['Content']['title']}}</h4>
                      <div class="card-text-news"><small>{{ dateFormat(viewData.posts[2]['Content']['publish_date']) }}</small></div>
                      
                    </div>
                  </router-link>
                </div>
                <div v-if="viewData.posts[3]" class="card card-horizontal">
                  <router-link :to="{ name: 'postDetail', params: {postSlug: viewData.posts[3]['Content']['slug']} }">
                    <div class="row no-gutters">
                      <div class="col-4">
                        <img class="card-img" alt="capa noticia" :src="viewData.posts[3]['FeaturedImage']['croppedImg'].url">
                      </div>
                      <div class="col-8">
                        <div class="card-body">
                          <h4 class="card-title-news">{{ viewData.posts[3]['Content']['title']}}</h4>
                          <div class="card-text-news"><small>{{ dateFormat(viewData.posts[3]['Content']['publish_date']) }}</small></div>
                          
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <!-- <div class="row my-5">
              <div class="col text-center">
                <router-link :to="{ name: 'postList' }" class="btn btn-outline-primary">Ver mais</router-link>
              </div>
            </div> -->
            <BaseSpinner v-if="loading"/>
          </div>
        </div>
      </div>

      <div data-anchor="regulamento" class="">
        <div class="rules">
          <div class="row mb-11">
            <div class="rules-content col-lg-7">
              <h2 class="h1 mb-11">Regulamento</h2>
              <!-- <div role="tablist" class="accordion faq">
                <b-card no-body class="faq-card" v-for="question in faq_questions" :key="question.key">
                  <b-card-header header-tag="header" class="faq-card-header" role="tab">
                    <b-link class="faq-card-header-button" href="#" v-b-toggle="'accordion'+question.key">
                      <div class="faq-card-header-title">{{question.question}}</div>
                      <div class="faq-card-header-addon"><span class="fas fa-fw fa-arrow-circle-down"></span></div>
                    </b-link>
                  </b-card-header>
                  <b-collapse class="faq-card-collapse" :id="'accordion'+question.key" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>{{question.answer}}</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="rules-buttons col">
              <div class="vertical-buttons">
               <router-link class="d-block btn btn-outline-primary" :to="{ name: 'regulation' }">Regulamento completo</router-link>
                <a v-if="customData.rules.rules_file" class="btn btn-outline-primary d-block" :href="customData.rules.rules_file" target="_blank">Download</a>
              </div>
             
            </div>
          </div>
        </div>
      </div>



      <div data-anchor="footer" class="section fp-auto-height">
        <div class="row mx-0">
          <div class="col px-0">
            <footer class="footer">
              <div class="sponsor">
                <img class="sponsor-img text-center" src="@/assets/images/barra-logos.png" alt="Chancelas">
              </div>
              <!-- <div class="sponsors">
                <div class="sponsor">
                  <figure class="figure">
                    <a href="" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/arte-lei-de-incentivo-a-cultura.png" alt="Arte da Lei de Incentivo à Cultura">
                    </a>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption align-self-start">Patrocínio</figcaption>
                    <div class="mx-n4">
                      <a href="//www.3m.com.br/" target="_blank">
                        <img class="sponsor-img" src="@/assets/images/logo-3m.png" alt="Logo da 3M">
                      </a>
                      <a href="//sebrae.com.br/" target="_blank">
                        <img class="sponsor-img" src="@/assets/images/logo-sebrae.png" alt="Sebrae">
                      </a>
                    </div>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption align-self-start">Apoio</figcaption>
                    <a href="//www.progen.com.br/" target="_blank">
                      <img class="sponsor-img" src="@/assets/images/logo-progen-white.png" alt="Logo do Mercado Livre">
                    </a>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Organização</figcaption>
                    <a href="//elo3.com.br/" target="_blank">
                      <img class="sponsor-img px-4" src="@/assets/images/logo_elo3_rosa.png" alt="Logo elo 3">
                    </a>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption align-self-start">Realização</figcaption>
                    <div class="mx-n4">
                      <a href="//pt.wikipedia.org/wiki/Organiza%C3%A7%C3%A3o_Mundial_da_Criatividade" target="_blank">
                        <img class="sponsor-img" src="@/assets/images/logo-wco-white.png" alt="World Creativity Organization">
                      </a>
                      <a href="//www.gov.br/turismo/pt-br" target="_blank">
                        <img class="sponsor-img" src="@/assets/images/secretaria-e-turismo.png" alt="Arte Pátria Amada Brasil">
                      </a>
                    </div>
                  </figure>
                </div>
              </div> -->


              <!-- <div class="sponsors">
                <div class="sponsor">
                  <figure class="figure">
                    <img class="sponsor-img" src="@/assets/images/arte-lei-de-incentivo-a-cultura.png" alt="Arte da Lei de Incentivo à Cultura">
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Patrocínio:</figcaption>
                    <img class="sponsor-img" src="@/assets/images/logo-3m.png" alt="Logo da 3M">
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Apoio:</figcaption>
                    <img class="sponsor-img" src="@/assets/images/logo-progen-white.png" alt="Logo Progen">
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Organização:</figcaption>
                    <img class="sponsor-img" src="@/assets/images/logo-elo-3.svg" alt="Logo elo 3">
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption">Parcerias:</figcaption>
                    <div class="mx-n4">
                      <img class="sponsor-img px-4" src="@/assets/images/logo-razoes-para-acreditar.png" alt="Logo do Razões para Acreditar">
                      <img class="sponsor-img px-4" src="@/assets/images/logo-voaa.png" alt="Logo do Voaa">
                      <img class="sponsor-img px-4" src="@/assets/images/logo-zupi-co.png" alt="Logo do Zupi.co">
                    </div>
                  </figure>
                </div>
                <div class="sponsor">
                  <figure class="figure">
                    <figcaption class="sponsor-caption align-self-start">Realização:</figcaption>
                    <img class="sponsor-img w-100" src="@/assets/images/arte-patria-amada-brasil.png" alt="Arte Pátria Amada Brasil">
                  </figure>
                </div>
              </div> -->
            </footer>
          </div>
        </div>
      </div>
    </full-page>
</template>

<script>
  //import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true (https://github.com/alvarotrigo/vue-fullpage.js)
  // import { loadDateProgressbar } from '../assets/scripts/date_progressbar'
  import { getServerURL } from "../const";

  import Vue from 'vue';
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import axios from 'axios'
  import VueFullPage from 'vue-fullpage.js'
  import moment from 'moment'
  import BaseSpinner from '@/components/BaseSpinner'
  import DateProgressBar from '@/components/DateProgressBar'
  import TheHeroMobile from '@/components/TheHeroMobile'
  import Curators from '@/components/Curators'
  // import Mobilizers from '@/components/Mobilizers'
  // import Community from '@/components/Community'

  Vue.use(VueFullPage);
  Vue.component(VueCountdown.name, VueCountdown);

  export default {
    name: 'home',
    components: {
      BaseSpinner,
      DateProgressBar,
      TheHeroMobile,
      Curators,
      // Mobilizers,
      // Community,
    },
    props: {
      login: Boolean,
    },
    data() {
      return {
        customData: { 'general':{}, 'home':{}, 'rules':{}, 'stages':{} },
        viewData: { 'posts':[] },
        loading: false,
        errored: false,
        mobilizerSlug: this.$route.params.mobilizerSlug || null,
        curatorSlug: this.$route.params.curatorSlug || null,
        showLogin: this.$route.name === 'login' ? true : this.login || false,
        showActivateCuratorProfile: this.$route.name === 'activateCuratorProfile',
        fullpageOptions: {
          animateAnchor: false,
          autoScrolling: false,
          navigation: false,
          responsiveWidth: 0,
          responsiveHeight: 0
        },
        fullpageFinalOptions: {
          animateAnchor: false,
          autoScrolling: true,
          navigation: true,
          responsiveWidth: 1200,
          responsiveHeight: 720
        },
      }
    },
    mounted () {
      this.loading = true;
      axios.get(getServerURL('/home'))
        .then(response => {
          this.customData = response.data.responseData.viewConfig;
          this.viewData = response.data.responseData;
          this.fullpageOptions = this.fullpageFinalOptions;
          this.$refs.dateProgress.loadData(this.customData.stages.stage_dates);
        })
        .catch(() => {
          // console.error(error);
          this.errored = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
    computed: {
      faq_questions(){
        try { return JSON.parse(this.customData.rules.questions) } catch(e){ return [] }
      }
    },
    methods: {
      dateFormat(dateStr) {
        return moment(dateStr).format('DD/MM/YYYY HH:mm')   // e.g. 24/05/2019 10:15
      },
      safe: value => value || {},
      getTime: date => (new Date(date) - new Date())
    }
  }
</script>

<style src="fullpage.js/dist/fullpage.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>


.card-img-overlay-hover {
  color: transparent;
  transition: color .15s ease-in-out;

  &:hover {
    color: inherit;
    background-color: rgb(71 0 86 / 80%);
  }
}

.category {
  height: 82vh;
  -webkit-overflow-scrolling: touch;
}

.sub-category {
  height: 66vh;

  @media (min-width: 768px) {
    height: 70vh;
  }
}

.category-img {
  height: 100%;
  overflow: hidden;
}

::-webkit-scrollbar {
  @media (min-width: 768px) {
    height: 12px;
    background: #FFF;
    border-radius: 10px;

    &-thumb {
      background: #470056;
      border: 1px solid #FFF;
      border-radius: 10px;
    }
  }
}

.fullscreen-bg {
  position: relative;
  z-index: 0;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  z-index: -100;
}


</style>
