export const serverConfig = {
    baseUrl: process.env.VUE_APP_API_ENDPOINT, // 'http://localhost/projecthub/ppp/api', 'https://projecthub.com.br/ppp/api',
    mocky: 'http://www.mocky.io/v2/'
};
 
export const useMock = false;
// export const useMock = true;

export function getServerURL(path, status = '200') {
    return useMock ? getMockURL(path, status) : serverConfig.baseUrl + path;
}

export function getMockURL(path, status = '200') {
  const mocks = {
    '/home': {
      '200': serverConfig.mocky + '5d6691443300002d00449dca',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/login': {
      '200': serverConfig.mocky + '5d52d1852e0000650081dc48',
      '401': serverConfig.mocky + '5d336082340000e542749feb',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/logout': {
      '200': serverConfig.mocky + '5d2de22a2e00002800c58205',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/signup': {
      '201': serverConfig.mocky + '5d336c5a3400006700749ffa',
      '422': serverConfig.mocky + '5d336cda340000e542749ffb',
      '500': serverConfig.mocky + '5d336153340000da3b749fee',
    },
    '/user/signup/validation': {
      '200': serverConfig.mocky + '5d3249f73300006b007ba617',
      '401': serverConfig.mocky + '5d336f46340000e542749ffe',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/update': {
      '200': serverConfig.mocky + '5d2de22a2e00002800c58205',
      '422': serverConfig.mocky + '5d336cda340000e542749ffb',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user': {
      '200': serverConfig.mocky + '5d52d3a42e0000480081dc51',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/forgotPassword': {
      '200': serverConfig.mocky + '5d2de22a2e00002800c58205',
      '401': serverConfig.mocky + '5d2de40c2e00004900c58212',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/projects': {
      '200': serverConfig.mocky + '5d4da1a9330000d43f337943',
      '201': serverConfig.mocky + '5d33843a340000ed3d74a027',
      '422': serverConfig.mocky + '5d2207962f00006e2cc463a4',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/projects/:id': {
      '200': serverConfig.mocky + '5d484c1f330000f343a3ee71',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/projects/:id/file/:filename': {
      '200': serverConfig.mocky + '5d2de22a2e00002800c58205',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/curator/projects/ratings': {
      '200': serverConfig.mocky + '5d2de22a2e00002800c58205',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/curator/projects/ratings?projectId=:id': {
      '200': serverConfig.mocky + '5d6415543200005300ba1f5d',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/polling/criterias?projectId=:id': {
      '200': serverConfig.mocky + '5d56c949300000680030aced',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    // '/curator/projects/ratings/:id': {
    //   '200': serverConfig.mocky + '5d2de22a2e00002800c58205',
    //   '500': serverConfig.mocky + '5d336153340000da3b749fee'
    // },
    '/curator/activate': {
      '200': serverConfig.mocky + '5d2de22a2e00002800c58205',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/curators': {
      '200': serverConfig.mocky + '5d98ab343400005d00f48a89',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/posts': {
      '200': serverConfig.mocky + '5d67de4e330000b179e65add',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/curators/projects': {
      '200': serverConfig.mocky + '5d7907bd3000006c4631f91e',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/categories': {
      '200': serverConfig.mocky + '5d98b4eb340000f80af48a9d',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/user/project/status': {
      '200': serverConfig.mocky + '5d2f297f3400000d0064d6ad',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/mobilizers': {
      '200': serverConfig.mocky + '5d4995133200008942600dec',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/communities': {
      '200': serverConfig.mocky + '5d4383652f00000e00179221',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/recommendations': {
      '200': serverConfig.mocky + '5d2de22a2e00002800c58205',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/ratings': {
      '201': serverConfig.mocky + '5d336c5a3400006700749ffa',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    },
    '/ratings/questions': {
      '200': serverConfig.mocky + '5d652ba33400004d00f44628',
      '500': serverConfig.mocky + '5d336153340000da3b749fee'
    }
  }
  return mocks[path] ? mocks[path][status] : path
}

// LOGIN MOCKS

// Curator
// http://www.mocky.io/v2/5d52d1852e0000650081dc48

// Entrepreneur
// http://www.mocky.io/v2/5d52d1b32e0000650081dc49

// Projects w/o ratings
// http://www.mocky.io/v2/5d4da179330000234b337941

// Projects w/ ratings
// http://www.mocky.io/v2/5d5415ab2f00004237861405
