import axios from "axios"
import { getServerURL } from "@/const"

const state = {
  status: {},
  statusList: [],
  loading: false
}

const getters = {}

const actions = {
  getStatusList ({ commit }) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL('/status'))
        .then(response => {
          if (response.data.responseData.success === true || response.data.responseData.success === 'true') {
            const statusList = response.data.responseData.statusList
            commit('setStatusList', statusList)
            commit('finishLoading')
            resolve(response)
          }
        })
        .catch(error => {
          commit('finishLoading')
          reject(error)
        })
    })
  }
}

const mutations = {
  startLoading(state) {
    state.loading = true
  },
  finishLoading(state) {
    state.loading = false
  },
  setStatusList(state, statusList) {
    state.statusList = statusList
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
