// feature detect
if ('serviceWorker' in navigator) {
  // remove service workers
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    if (registrations.length) {
      for(let registration of registrations) {
        registration.unregister()
      }
    }
  })

  // clear application cache
  caches.keys().then(function(cacheNames) {
    cacheNames.forEach(function(cacheName) {
      caches.delete(cacheName);
    });
  });
}
