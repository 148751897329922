<template>
  <div class="BaseContainer">
    <b-container>
      <b-row>
        <b-col md="8" offset-md="2">
          <slot></slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "BaseContainer"
};
</script>

<style lang="scss" scoped>
</style>
