<template>
  <div>
    <b-jumbotron bg-variant="dark" fluid>
      <Navbar />
      <b-row>
        <b-col>
          <div class="jumbotron-text">
            <h1>Notícias</h1>
          </div>
        </b-col>
      </b-row>
    </b-jumbotron>
    <div class="container mb-5">
      <div class="row">
        <div class="col col-lg-4">
          <!-- <a class="btn btn-outline-primary w-100 mb-7" href="#">Assine nossa newsletter</a> -->
          <p class="font-weight-bolder text-uppercase">Premio Pretas Potências nas redes sociais</p>
          <div class="social-links mb-7">
            <a class="social-link" :href="customData.general.facebook" v-if="customData.general.facebook">
              <i class="fab fa-facebook"></i>
            </a>
            <a class="social-link" :href="customData.general.twitter" v-if="customData.general.twitter">
              <i class="fab fa-twitter"></i>
            </a>
            <a class="social-link" :href="customData.general.instagram" v-if="customData.general.instagram">
              <i class="fab fa-instagram"></i>
            </a>
            <a class="social-link" :href="customData.general.linkedin" v-if="customData.general.linkedin">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
          <div>
            <!-- <p class="mb-7 font-weight-bolder text-uppercase">@PremioBrasilCriativo no Instagram</p> -->
            <!-- <h3>[ THUMBS ]</h3> -->
            <!-- <iframe src="//lightwidget.com/widgets/556b1e77fbb05b4191fe319f087497b8.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;min-height:300px"></iframe> -->
          </div>
        </div>
        <div class="col col-lg-8">
          <div class="row">
            <div class="col col-12 col-lg-4 mb-7" v-for="post in viewData.posts" :key="post['Content']['id']">
              <router-link :to="{ name: 'postDetail', params: { postSlug: post['Content']['slug']} }">
                <b-card
                  :title="post['Content']['title']"
                  :img-src="safe(safe(post.FeaturedImage).croppedImg).url"
                  img-alt="Capa Noticia"
                  img-top
                  tag="article"
                  style="max-width: 20rem;"
                  class="mb-2"
                ></b-card>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getServerURL } from "@/const";
import Navbar from "@/components/Navbar";
// import { safe } from "@/helpers/utils";
import axios from "axios";

export default {
  name: "PostList",
  components: {
    Navbar
  },
  data() {
    return {
      customData: { general: {} },
      viewData: { posts: [] },
      loading: true,
      errored: false
    };
  },
  methods: {
    safe: value => value || {}
  },
  mounted() {
    axios
      .get(getServerURL("/posts"))
      .then(response => {
        this.customData = response.data.responseData.viewConfig || {general:{}};
        this.viewData = response.data.responseData;
      })
      .catch(() => {
        this.errored = true;
      })
      .finally(() => {
        this.loading = false;
      });

    // let instagramThumbs = document.createElement('script')
    // instagramThumbs.setAttribute('src', '//cdn.lightwidget.com/widgets/lightwidget.js')
    // document.head.appendChild(instagramThumbs)
  }
};
</script>

<style scoped>

.card-img-top {
  height: 167px;
  object-fit: cover
}

</style>


