<template>
  <div>
    <b-container>
      <b-row>
        <b-col class="pt-11 ml-11 mb-11">
          <b-link :to="{ name: 'home' }" class="btn btn-link">
            <i class="fas fa-long-arrow-alt-left"></i> Voltar
          </b-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="px-15">
          <div class="p-5 my-15 text-center">
              <h2>Página não encontrada</h2> <!-- TODO: completar -->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: 'NotFound'
  }
</script>

<style lang="scss" scoped>
    body {
        background: url('../assets/images/old/header_bg.png'); 
    }
</style>
