<template>
  <div class="RecommendationForm">
    <b-form novalidate class="RecommendationForm__form" :validated="wasValidated" @submit.prevent="handleSubmit">
      <b-form-row>
        <b-col sm="8">
          <b-form-group label-sr-only label="nome do(a) curador(a)" label-for="curatorName">
            <b-form-input required placeholder="nome do(a) curador(a)" minlength="5" id="curatorName" v-model="formData.curator_name"></b-form-input>
            <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-sr-only label="categoria" label-class="h5">
            <!-- <b-form-radio-group plain required id="category" name="category" v-model="formData.category_name">
              <b-form-radio v-for="category in categories" :key="category.id" :value="category.name">{{ category.name }}</b-form-radio>
            </b-form-radio-group> -->
            <b-form-select required id="curatorCategory" v-model="formData.category_name">
              <option :value="''" disabled>categoria</option>
              <option v-for="category in categories" :key="category.id" :value="category.name">{{ category.name.toLowerCase() }}</option>
            </b-form-select>
            <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="8">
          <b-form-group label-sr-only label="email" label-for="curatorEmail">
            <b-form-input placeholder="email" type="email" id="curatorEmail" aria-describedby="curatorEmailHelp" v-model="formData.curator_email"></b-form-input>
            <b-form-text text-variant="primary" id="curatorEmailHelp">Não obrigatório</b-form-text>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-sr-only label="celular com ddd" label-for="curatorPhone">
            <b-form-input placeholder="celular com ddd" type="tel" id="curatorPhone" aria-describedby="curatorPhoneHelp" v-model="formData.curator_phone"></b-form-input>
            <b-form-text text-variant="primary" id="curatorPhoneHelp">Não obrigatório</b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm>
          <b-form-group label-sr-only label="facebook" label-for="curatorFacebook">
            <b-form-input placeholder="https://facebook.com/curador(a)" type="url" id="curatorFacebook" aria-describedby="curatorFacebookHelp" v-model="formData.curator_facebook"></b-form-input>
            <b-form-text text-variant="primary" id="curatorFacebookHelp">Não obrigatório</b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm>
          <b-form-group label-sr-only label="linkedin" label-for="curatorLinkedin">
            <b-form-input placeholder="https://linkedin.com/in/curador(a)" type="url" id="curatorLinkedin" aria-describedby="curatorLinkedinHelp" v-model="formData.curator_linkedin"></b-form-input>
            <b-form-text text-variant="primary" id="curatorLinkedinHelp">Não obrigatório</b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-button class="align-self-lg-center" type="submit" variant="outline-primary">Salvar</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "RecommendationForm",
  props: {
    formData: Object,
    categories: Array,
    wasValidated: Boolean
  },
  methods: {
    handleSubmit(event) {
      this.$emit("formSubmit", event, this.formData);
    }
  }
}
</script>

<style lang="scss" scoped>
.RecommendationForm {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
