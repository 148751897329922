<template>
  <div class="TheHeroActivateCuratorProfile">
    <form
      class="TheHeroActivateCuratorProfile__form"
      :class="{ 'was-validated': wasValidated }"
      @submit.prevent="activateCuratorProfile"
      novalidate
    >
      <h4 class="text-center h4 mb-9">Ativação de perfil curador</h4>
      <p class="text-center h5 mb-9">Digite aqui o seu e-mail</p>
      <div class="input-group mb-9">
        <div class="input-group-prepend">
          <span class="input-group-text input-group-text-slim">
            <i class="far fa-fw fa-envelope"></i>
          </span>
        </div>
        <label for="emailActivateCuratorProfile" class="sr-only">Email</label>
        <input
          type="email"
          class="form-control form-control-slim"
          placeholder="email"
          id="emailActivateCuratorProfile"
          v-model="emailActivateCuratorProfile"
          minlength="10"
          required
        />
        <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
      </div>
      <input type="submit" class="btn btn-outline-primary mb-7" value="enviar" />
    </form>
    <b-alert variant="danger" dismissible fade v-model="showErrorMessage">
      <h5 class="h5">Erro!</h5>
      <p class="mb-0">{{ errorMessage }}</p>
    </b-alert>
    <b-alert variant="success" dismissible fade v-model="showSuccess">
      <h5 class="h5">Sucesso!</h5>
      <p class="mb-0">Foi enviado um e-mail para {{ emailActivateCuratorProfile }} contendo senha e link de acesso para a plataforma!</p>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "HeroActivateCuratorProfile",
  data() {
    return {
      emailActivateCuratorProfile: "",
      wasValidated: false,
      errorMessage: "",
      showErrorMessage: false,
      showSuccess: false
    }
  },
  methods: {
    activateCuratorProfile: function () {
      this.$store
        .dispatch("curator/activateCuratorProfile", this.emailActivateCuratorProfile)
          .then(() => {
            this.showSuccess = true;
          })
          .catch(({ response: { data: { responseData: { errorMessage } } } }) => {
            this.errorMessage = errorMessage;
            this.showErrorMessage = true;
          });
    }
  }
};
</script>

<style lang="scss">
.TheHeroActivateCuratorProfile__form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 20vh;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  @media (min-width: 768px) {
    padding-top: 14.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 992px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 1200px) {
    padding-right: 2.5rem;
  }
}

.form-control-slim {
  font-size: 1rem;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
}
</style>
