<template>
  <div class="BaseSpinner">
    <i class="fas fa-circle-notch fa-spin fa-2x"></i>
  </div>
</template>

<script>
export default {
  name: "BaseSpinner"
}
</script>

<style lang="scss">
.BaseSpinner {
  display: flex;
  justify-content: center;
}
</style>
