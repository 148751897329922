<template>
  <div class="curators section" data-anchor="jurados">
    <b-row class="mx-0 min-vh-100">
      <b-col class="p-11">
        <h2 class="h1 mb-6">Jurados</h2>
        <div class="d-none d-lg-flex">
          <CardRowNav :items="[{ id: null, name: 'todos' }, ...categories]" @itemActivated="addFilter" @itemDeactivated="removeFilter" scrollable />
        </div>
        <b-row>
          <b-col class="d-lg-none">
            <b-form-group>
              <b-form-select v-model="currentCategoryId" @change="handleCurrentCategoryIdChange">
                <option :value="''" disabled>categoria</option>
                <option :value="null">todos</option>
                <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name.toLowerCase() }}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <CardRowItems :items="filteredCurators || curators" items-route-to="/curador" items-route-hash="#jurados" item-img="photo" item-img-sub="url" item-mini-bio="mini_bio" item-title="name" item-slug="premium_slug" @itemClicked="handleCuratorClick"/>
        <b-modal id="curatorsCuratorDetailModal" dialog-class="fullscreen-modal-dialog" content-class="fullscreen-modal-content p-lg-0" body-class="fullscreen-modal-body p-0" hide-header hide-footer v-if="curator">
          <template slot="default">
            <b-row class="min-vh-100 w-100">
              <b-col lg="4" class="d-none d-lg-block pr-0" v-if="curator.photo && curator.photo.url">
                <img :src="curator.photo.url" class="card-row-item-detail__img">
              </b-col>
              <b-col lg="8" class="p-lg-11">
                <b-row class="mb-5">
                  <b-col cols="12" lg="2" order-lg="2" class="mb-7 mb-lg-0">
                    <b-link class="close fullscreen-close" href="#" @click="handleCuratorDismiss">×</b-link>
                  </b-col>
                  <b-col cols="12" lg="10" order-lg="1" class="mb-7 mb-lg-0">
                    <!-- <ul class="list-unstyled mb-0 mx-n3" v-if="curator.years">
                      <li class="d-inline-block h5 px-3" v-for="(year, index) in curator.years" :key="index">{{ year }}</li>
                    </ul> -->
                    <ul class="list-unstyled mb-0 mx-n3" v-if="curatorCategories">
                      <li class="d-inline-block h5 px-3" v-for="filter in curatorCategories" :key="filter.id">{{ filter.name }}</li>
                    </ul>
                    <h4 class="h4 mb-0">{{ curator.name }}</h4>
                  </b-col>
                </b-row>
                <b-row class="mb-8">
                  <b-col>
                    <article>{{ curator.bio }}</article>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="social-links mb-8">
                      <a class="social-link" v-if="curator.facebook" :href="curator.facebook"><i class="fab fa-facebook"></i></a>
                      <a class="social-link" v-if="curator.twitter" :href="curator.twitter"><i class="fab fa-twitter"></i></a>
                      <a class="social-link" v-if="curator.instagram" :href="curator.instagram"><i class="fab fa-instagram"></i></a>
                      <a class="social-link" v-if="curator.linkedin" :href="curator.linkedin"><i class="fab fa-linkedin"></i></a>
                      <b-button variant="link" class="social-link" v-clipboard:copy="linkToShare" v-clipboard:success="onCopy"><i class="fas fa-share"></i></b-button>
                    </div>
                    <p v-show="showLinkCopied">Copiado! <i class="fas fa-check-circle text-success"></i></p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CardRowNav from "@/components/CardRowNav"
import CardRowItems from "@/components/CardRowItems"
import Vue from "vue";
import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true; // in order to work with modals
Vue.use(VueClipboard);

export default {
  name: "Curators",
  components: {
    CardRowNav,
    CardRowItems
  },
  props: {
    curatorSlug: String
  },
  data() {
    return {
      curator: {},
      curatorCategories: [],
      currentCategoryId: "",
      filteredCurators: null,
      linkToShare: "",
      showLinkCopied: false
    }
  },
  computed: {
    ...mapState("category", ["categories"]),
    ...mapState("curator", ["curators"]),
    filteredCuratorBySlug: function () {
      return this.curators.filter(curator => curator.premium_slug === this.curatorSlug, this);
    }
  },
  methods: {
    ...mapActions("category", ["getCategories"]),
    ...mapActions("curator", ["getCurators"]),
    onCopy: function () {
      this.showLinkCopied = true;
    },
    addFilter: function (item) {
      if (item.id) {
        return this.filteredCurators = this.curators.filter(curator => curator.category_ids.includes(item.id));
      } else {
        return this.filteredCurators = null;
      }
    },
    removeFilter: function () {
      return this.filteredCurators = null;
    },
    handleCuratorClick: function () {
      // this.$router.push({ name: "curatorDetail", hash: "#jurados", params: { curatorSlug: curator.slug } });
      // this.curator = curator;
      // this.curatorCategories = this.categories.filter(category => curator.category_ids.includes(category.id));
      // this.$bvModal.show("curatorsCuratorDetailModal");
    },
    handleCuratorDismiss: function () {
      this.$bvModal.hide("curatorsCuratorDetailModal");
      setTimeout(() => this.$router.push({ name: "home", hash: "#jurados" }), 200);
    },
    handleCurrentCategoryIdChange: function () {
      if (this.currentCategoryId) {
        return this.filteredCurators = this.curators.filter(curator => curator.category_ids.includes(this.currentCategoryId));
      } else {
        return this.filteredCurators = null;
      }
    }
  },
  mounted() {
    this.getCategories();
    this.getCurators().then(() => {
      if (this.curatorSlug) {
        this.curator = this.filteredCuratorBySlug[0];
        this.curatorCategories = this.categories.filter(category => this.curator.category_ids.includes(category.id), this);
        this.linkToShare = `https://www.premiobrasilcriativo.com.br${this.$route.path}`;
        this.$bvModal.show("curatorsCuratorDetailModal");
      }
    });
  },
  // metaInfo () {
  //   const curatorTitle = this.curator ? this.curator.name : "";
  //   const curatorImg = this.curator && this.curator.photo ? this.curator.photo.url : "";
  //   const curatorDescription = this.curator ? this.curator.mini_bio : "";
  //   return {
  //     title: curatorTitle,
  //     meta: [
  //       { vmid: "og:type", property: "og:type", content: "article" },
  //       { vmid: "og:title", property: "og:title", content: curatorTitle },
  //       { vmid: "og:description", property: "og:description", content: curatorDescription },
  //       { vmid: "og:image", property: "og:image", content: curatorImg },
  //       { vmid: "twitter:title", name: "twitter:title", content: curatorTitle },
  //       { vmid: "twitter:description", name: "twitter:description", content: curatorDescription },
  //       { vmid: "twitter:image", name: "twitter:image", content: curatorImg },
  //       { vmid: "itemName", itemprop: "name", content: curatorTitle },
  //       { vmid: "itemDescription", itemprop: "description", content: curatorDescription },
  //       { vmid: "itemImage", itemprop: "image", content: curatorImg }
  //     ]
  //   }
  // },
}
</script>

<style lang="scss" scoped>
.card-row-item-detail__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
