<template>
  <div class="TheHeroDisplay">
    <strong class="mb-8 text-center">Ministério da Cultura, Banco BV, BASF, <br>Ernst & Young e Mercado Livre apresentam</strong> 
    <div class="logo-pbc"></div>
    <!-- <img class="img-fluid w-100 logo-desk" src="@/assets/images/logo_home.png" alt="Logo do Prêmio Pretas Potências"> -->
    <img class="img-fluid w-100" src="@/assets/images/logo_home.png" alt="Pretas Potencias">
    <!-- <a class="btn btn-outline-primary btn-home text-uppercase d-block mb-10" href="//bit.ly/3BVaaL3">Inscreva-se</a> -->
    <!-- <router-link class="btn btn-outline-primary btn-home text-uppercase d-block my-10" to="/registrar">Inscreva-se</router-link> -->
    <!-- <router-link class="btn btn-outline-primary text-uppercase d-block d-md-none mb-10" :to="{ name: 'invite', query: { o: 'Z2VuZXJ' } }">Garanta seu ingresso</router-link> -->
    <!-- <router-link class="btn btn-outline-primary btn-home text-uppercase d-block mb-10" :to="{ name: 'postDetail', params: { postSlug: 'premio-brasil-criativo-apresenta-vencedores-da-sua-edicao-2023'} }">Conheça os vencedores</router-link> -->
    <p class="h2 mt-12">Inscrições encerradas!</p>
    <div class="powered-by">
    <p class="mt-12 text-center">Powered by <a href="https://projecthub.com.br" target="_blank">ProjectHub</a></p> 
    </div>
    <nav class="nav flex-column align-items-center d-md-none mb-7">
      <!-- <a class="h2" href="#o-premio">O prêmio</a> 
      <a class="h2" href="#categorias">Categorias</a>
      <a class="h2" href="#jurados">Jurados</a> -->
      <!--<a class="h2" href="#comunidade">Comunidade</a>-->
      <!-- <a class="h2" href="#mobilizadores">Mobilizadores</a>
      <a class="h2" href="#etapas">Etapas</a>
      <a class="h2" href="#noticias" v-if="loading || viewData.posts.length">Notícias</a> -->
      <!-- <a class="h2" href="#regulamento">Regulamento</a> -->
      <!--<a class="h2" href="#resultados">Resultados</a>-->
      <!-- <p class="h2 mt-6">Inscrições encerradas!</p> -->
      <!-- <p class="h3 mt-6">FAÇA PARTE DA FESTA! <br><br>
        24/JAN 18H00 <br><br>
        Centro Cultural São Paulo</p>
      <p class="h3 mt-6">PARTICIPE DA CERIMÔNIA DE PREMIAÇÃO EM SÃO PAULO</p> -->
      <!-- <div class="row d-flex align-items-end ml-1 mb-4" style="height: 100px;">
        <a class="btn btn-outline-primary btn-home" href="//bit.ly/3BVaaL3">
          Inscreva-se
        </a>
      </div> -->
    </nav> 
    <div class="text-center">
      <!-- <router-link class="btn btn-outline-success text-uppercase d-none d-md-block mb-md-11" to="/registrar">Inscreva-se</router-link> -->
      <!-- <router-link class="btn btn-outline-success text-uppercase d-none d-md-block mb-md-11" :to="{ name: 'invite', query: { o: 'Z2VuZXJ' } }">Garanta seu ingresso</router-link> -->

      <!-- <h4 class="text-uppercase">Até 25 de agosto</h4> -->
      
      <template v-if="customData.home.countdown_date">
        <div class="mb-10">
          <h2 class="sr-only">Situação</h2>
          <template v-if="getTime(customData.home.countdown_date) <= 0">
            <p class="h2">{{ customData.home.countdown_finish }}</p> 
          </template>
          <template v-else>
            <countdown :time="getTime(customData.home.countdown_date)" tag="h3">
              <template slot-scope="props" class="text-uppercase">
                <p class="h3">{{ customData.home.countdown_label }}<br>
                {{props.days}} dias, {{props.hours}} horas e {{props.minutes}} minutos</p>
              </template>
            </countdown>
          </template>
        </div>
      </template>
      
      <div class="social-links justify-content-center">
        <a v-if="customData.general.facebook" class="social-link" :href="customData.general.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
        <a v-if="customData.general.twitter" class="social-link" :href="customData.general.twitter" target="_blank"><i class="fab fa-twitter"></i></a>
        <a v-if="customData.general.instagram" class="social-link" :href="customData.general.instagram" target="_blank"><i class="fab fa-instagram"></i></a>
        <a v-if="customData.general.linkedin" class="social-link" :href="customData.general.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

export default {
  name: 'TheHeroDisplay',
  props: [
    'loading',
    'custom-data',
    'view-data'
  ],
  methods: {
    getTime: date => (new Date(date) - new Date())
  }
}
</script>
