<template>
  <div class="TheHeroLoginWrapper">
    <form
      novalidate
      :class="{ 'was-validated': wasLoginValidated }"
      class="TheHeroLogin"
      @submit.prevent="login"
    >
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text input-group-text-slim"><i class="far fa-fw fa-envelope"></i></span>
          </div>
          <label for="email" class="sr-only">Email</label>
          <input
            type="email"
            class="form-control form-control-slim"
            placeholder="Email"
            id="email"
            v-model="email"
            minlength="10"
            required
          >
          <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
        </div>
      </div>
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text input-group-text-slim"><i class="fas fa-fw fa-lock"></i></span>
          </div>
          <label for="password" class="sr-only">Senha</label>
          <input
            type="password"
            class="form-control form-control-slim"
            placeholder="Senha"
            id="password"
            v-model="password"
            minlength="8"
            required
          >
          <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
        </div>
        <b-link class="form-text d-inline-block" @click="handleShowForgotPassword"><small>esqueceu a senha?</small></b-link>
      </div>
      <b-button class="mb-7" type="submit" variant="outline-success">login</b-button>
      <!-- <router-link class="mx-auto mb-7" :to="{ name: 'entrepreneurSignUp' }"><small>ou registre-se</small></router-link> -->
      <BaseSpinner v-if="loading" />
      <b-alert class="mb-0" variant="danger" dismissible fade v-model="showError">
        <h5 class="h5">Erro!</h5>
        <p class="mb-0">{{ error }}</p>
      </b-alert>
    </form>
  </div>
</template>

<script>
import BaseSpinner from "@/components/BaseSpinner"

export default {
  name: "TheHeroLogin",
  components: {
    BaseSpinner
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      showError: false,
      wasLoginValidated: false,
      loading: false
    }
  },
  computed: {
    isCurator: {
      get() {
        return this.$store.getters["user/isCurator"];
      }
    }
  },
  methods: {
    login(e) {
      this.wasLoginValidated = true;
      if (e.target.checkValidity()) {
        this.loading = true;
        this.$store
          .dispatch("user/login", {
            email: this.email,
            password: this.password
          })
          .then(({ data: { responseData } }) => {
            if (responseData.success == true) {
              if (this.isCurator) {
                this.$router.push({ name: "curatorDashboard" });
              } else {
                this.$router.push({ name: "entrepreneurDashboard" });
              }
            }
          })
          .catch(({ response: { data: { responseData } } }) => {
            this.error = responseData.errorMessage;
            this.showError = true;
          })
          .catch(() => {
            this.error = "Serviço indisponível.";
            this.showError = true;
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    handleShowForgotPassword() {
      this.$emit("showForgotPassword");
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control-slim {
  font-size: 1rem;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
}
</style>
