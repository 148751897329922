<template>

  <div v-if="loading"><h3>Carregando...</h3></div>

  <div v-else class="date-progress-bar-container mb-11 mb-lg-0">
    <div class="date-progress-bar">
      <div v-for="(point, index) in stageDates" :key="index"
              :class="'progress-point '+(index % 2?'bottom':'top')"
              :data-date="point.initial_date">
        <div class="progress-tooltip">
          <span class="progress-title">{{point.title}}</span>
          <span class="progress-info">{{ loadDateMessage(point.initial_date, point.final_date) }}</span>
        </div>
      </div>
      <div class="total-progress"></div>
    </div>
  </div>

</template>

<script>
    import { loadDateProgressbar } from '../assets/scripts/date_progressbar'

    export default {
        name: 'date-progressbar',
        data() {
            return {
                stageDates: null, loading: null
            }
        },
        methods: {
            loadDateMessage(initial, final='') {
                const finalMsg = final.substring(0,5);  //show date message
                return initial.substring(0,5) + (finalMsg ? ' a '+finalMsg : '');
            },
            loadData(stageDates){
                try { this.stageDates = JSON.parse(stageDates) } catch(e){ this.stageDates = [] }

                loadDateProgressbar()
            }
        }
    }
</script>


<style scoped>

</style>
