import axios from "axios";
import { convertToFormData } from "@/helpers/utils";

function save(formData) {
  return axios.post(
    "https://script.google.com/macros/s/AKfycbyhLopVOON3fxnOSlm4ouG9hlzYnCG1gr-v8uhQYovp10hzsJwL/exec",
    convertToFormData(formData)
  );
}

export default {
  save
};
