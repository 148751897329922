<template>
  <div class="BaseJumbotron">
    <b-jumbotron bg-variant="dark" fluid>
      <Navbar />
      <b-row>
        <b-col>
          <div class="jumbotron-text">
            <h1>{{ title }}</h1>
          </div>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "BaseJumbotron",
  components: {
    Navbar
  },
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>

</style>
