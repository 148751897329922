import axios from "axios"
import { getServerURL } from "@/const"

export default {
  getCriterias(projectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL("/polling/criterias?projectId=:id").replace(":id", projectId))
        .then(response => {
          if (response.data.responseData.success == true) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
