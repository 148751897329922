import axios from "axios";
import { getServerURL } from "@/const";

const state = {
  mobilizer: {},
  mobilizers: [],
  loading: false
};

const getters = {};

const actions = {
  fetch({ commit }) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL("/mobilizers"))
        .then(response => {
          if (response.data.responseData.success == true) {
            const mobilizers = response.data.responseData.mobilizers;
            commit("setMobilizers", mobilizers);
            commit("finishLoading");
            resolve(response);
          }
        })
        .catch(error => {
          commit("finishLoading");
          reject(error);
        });
    });
  }
};

const mutations = {
  startLoading(state) {
    state.loading = true;
  },
  finishLoading(state) {
    state.loading = false;
  },
  setMobilizers(state, mobilizers) {
    state.mobilizers = mobilizers;
  },
  setMobilizer(state, mobilizer) {
    state.mobilizer = mobilizer;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
