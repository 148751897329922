import axios from "axios"
import { getServerURL } from "@/const"

export default {
  save(ratings) {
    return new Promise((resolve, reject) => {
      axios
        .post(getServerURL("/curator/projects/ratings"), ratings)
        .then(response => {
          if (response.data.responseData.success == true) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  get(projectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(getServerURL("/curator/projects/ratings?projectId=:id").replace(":id", projectId))
        .then(response => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
