<template>

<b-container>
  <b-row>
    <b-col class="pt-11 ml-11 mb-11">
      <b-link :to="{ name: 'home' }" class="btn btn-link">
        <i class="fas fa-long-arrow-alt-left"></i> Voltar
      </b-link>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="px-15">
      <div v-html="completeRules"></div>
    </b-col>
  </b-row>
</b-container>

</template>

<script>
import axios from "axios"
import { getServerURL } from "@/const"

export default {
  name: 'regulation',
  data() {
    return {
      completeRules: null
    }
  },
  mounted () {
    axios.get(getServerURL('/home'))
      .then(response => {
        this.completeRules = response.data.responseData.viewConfig.rules.complete_rules;
      })
  },
  // props: [
  //   'complete-rules'
  // ],
}
</script>
