<template>
  <div class="UserProfileCurator">
    <form
      novalidate
      :class="{ 'was-validated': wasProfileValidated }"
      @submit.prevent="handleSubmit"
    >
      <b-alert variant="danger" dismissible fade v-model="showErrorMessage">
        <h5 class="h5">Erro!</h5>
        <p class="mb-0">{{ errorMessage }}</p>
      </b-alert>
      <b-alert variant="danger" dismissible fade v-model="showErrors">
        <h5 class="h5">Erro!</h5>
        <ul class="list-unstyled mb-0">
          <li v-for="(error, index) in errors" :key="index">
            <div v-for="(errorMsg, index2) in error" :key="index2">{{ errorMsg }}</div>
          </li>
        </ul>
      </b-alert>
      <b-alert variant="success" dismissible fade v-model="showSuccess">
        <h5 class="h5">Sucesso!</h5>
        <p class="mb-0">Perfil atualizado com sucesso!</p>
      </b-alert>
      <div class="form-group">
        <label for="nome" class="sr-only">nome completo</label>
        <input
          type="text"
          class="form-control"
          placeholder="nome completo"
          id="nome"
          v-model="user.name"
          minlength="5"
          required
          @keyup="handleKeyUp"
        />
        <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm">
          <label for="email" class="sr-only">email</label>
          <input
            type="email"
            class="form-control"
            placeholder="email"
            id="email"
            v-model="user.email"
            minlength="8"
            required
          />
          <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
        </div>
        <div class="form-group col-sm">
          <label for="senha" class="sr-only">senha</label>
          <input
            type="password"
            class="form-control"
            placeholder="senha"
            id="senha"
            v-model="user.password"
            minlength="8"
            required
          />
          <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
        </div>
        <div class="form-group col-sm">
          <label for="celular" class="sr-only">celular com ddd</label>
          <input
            type="tel"
            class="form-control"
            placeholder="celular com ddd"
            id="celular"
            v-model="user.phone"
            minlength="10"
            required
          />
          <div class="invalid-feedback">Obrigatório (mín. 10 caracteres)</div>
        </div>
      </div>
      <div class="form-group">
        <label for="sobre" class="sr-only">fale sobre você</label>
        <textarea
          rows="10"
          class="form-control"
          placeholder="fale sobre você"
          aria-describedby="ajudaSobre"
          id="sobre"
          v-model="user.mini_bio"
          minlength="8"
          maxlength="300"
          required
        ></textarea>
        <small id="ajudaSobre" class="form-text">Máximo de 300 caracteres</small>
        <div class="invalid-feedback">Obrigatório (mín. 8 caracteres)</div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm">
          <label for="twitter" class="sr-only">twitter</label>
          <input
            type="url"
            class="form-control"
            placeholder="https://twitter.com/seu-perfil"
            aria-describedby="ajudaTwitter"
            id="twitter"
            v-model="user.twitter"
          />
          <small id="ajudaTwitter" class="form-text">Não obrigatório</small>
        </div>
        <div class="form-group col-sm">
          <label for="facebook" class="sr-only">facebook</label>
          <input
            type="url"
            class="form-control"
            placeholder="https://facebook.com/seu-perfil"
            aria-describedby="ajudaFacebook"
            id="facebook"
            v-model="user.facebook"
          />
          <small id="ajudaFacebook" class="form-text">Não obrigatório</small>
        </div>
        <div class="form-group col-sm">
          <label for="instagram" class="sr-only">instagram</label>
          <input
            type="url"
            class="form-control"
            placeholder="https://instagram.com/seu-perfil"
            aria-describedby="ajudaInstagram"
            id="instagram"
            v-model="user.instagram"
          />
          <small id="ajudaInstagram" class="form-text">Não obrigatório</small>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-10 mb-md-11">
        <input type="submit" class="btn btn-outline-primary ml-sm-3" value="Salvar" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UserProfileCurator",
  data() {
    return {
      wasProfileValidated: false,
      showErrorMessage: false,
      showErrors: false,
      showSuccess: false,
      errorMessage: "",
      errors: []
    };
  },
  computed: {
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapActions("user", ["updateUser"]),
    handleKeyUp() {
      if (!this.wasProfileValidated) {
        this.wasProfileValidated = true;
      }
    },
    handleSubmit(e) {
      this.wasProfileValidated = true;
      if (e.target.checkValidity()) {
        this.updateUser()
          .then(() => {
            this.showSuccess = true;
          })
          .catch(({ response: { data: { responseData } } }) => {
            if (responseData.errorMessage) {
              this.errorMessage = responseData.errorMessage;
              this.showErrorMessage = true;
            } else {
              this.errors = responseData.errors;
              this.showErrors = true;
            }
          });
      }
    }
  }
};
</script>

<style lang="scss">
</style>
